import Vue from 'vue'
import store from '../store'

let _this = null

export default {
    socket: null,
    servertimer: null,
    init(socket){
        this.socket = socket
        this.events()
        _this = this
    },
    events(){

        this.socket.on('connect', function(){
            store.commit('SetConnectionStatus', true);
        });

        this.socket.on('reconnect_attempt', (attemptNumber) => {
            
            console.log(attemptNumber)
        });

        this.socket.on('connect_error', (error) => {
            console.log(error)
        });

        this.socket.on('disconnect', ()=>{

            store.commit('SetConnectionStatus', false)

        })

        this.socket.on('response', (data)=> {

            let response = data
            let method = response['channel']
            console.log(method)
            _this[method](response['status'])

        })

    },
    close(){
        if(this.socket !== null && this.socket !== undefined) {
            this.socket.close()
        }
    },
    timer(data){

        this.servertimer ? clearInterval(this.servertimer) : {}
        let time = data
        time += 1000
        this.servertimer = setInterval(()=>{ store.commit('SetServerTime', time); time += 1000; }, 1000);

    },
    Relaunch(){
        this.socket.emit('relaunch')
    },
    Shutdown(){
        this.socket.emit('shutdown')
    },
    Reset(){
        this.socket.emit('reset')
    },
    'connected_users'(data){
        store.commit('SetOnline',data['clients'])
    },
    GetOnline(){
        this.socket.emit('get-online')
    },
    'student_disconnect'(data){
        console.log(data)
    },
    SetEnableBackup(){
        this.socket.emit('set-enable-backup')
    },
    StartMock(){

        let admin = store.getters.GetAdminInfo
        store.commit('SetMockExam',true)
        this.socket.emit('start-mock',admin)
    },
    StopMock(){

        let admin = store.getters.GetAdminInfo
        store.commit('SetMockExam',false)
        this.socket.emit('stop-mock',admin)
    },
    GetLogs(){
        this.socket.emit('read-logs', { file_name : new Date().toLocaleDateString().split('/').join('_') });
    },
    'read-logs'(data){
        store.commit('setLogs',data['logs'].reverse());
    },
    'student_log'(data){
        console.log(data)
    },
    'stopped-mock'(){
        console.log("Stopped Mock")
    },
    LockUserScreen(User){
        this.socket.emit('set-lock-sceen', User)
    },
    'send-screenshot'(data){
        store.commit('UpdateScreenShot',data)
    },
    SendAudio(data){
        this.socket.emit('send-audio', data)
    },
    SendNotification(data){
        this.socket.emit('send-notification', data)
    },
    attemptGetOnlineUsers(Students,data){
        this.socket.emit('online-users',data,(OnlineStudents) =>{
            store.commit('SetNewStudentsStatus',{ Students , OnlineStudents })
        })
    },
}
