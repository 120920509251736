const state = {
    "center_id": null,
    "admin_id": null,
    "user_name": null,
    "mobile_no": null,
    "institute_id": null,
    "center_name": null,
    "address": null,
    "city": null,
    "state": null,
    "country": null,
    'login_status': false,
    'wait': false,
    'BackUpServer': 1,
    'admin_user' : {},
    'admin_info' : {},
    'online'  : []
}

const mutations = {
    setAdmin(state, payload){
        state.admin_info = payload
        state.center_id = payload.center_id
        state.admin_id = payload.admin_id
        state.user_name = payload.user_name
        state.mobile_no = payload.mobile_no
        state.institute_id = payload.institute_id
        state.center_name = payload.center_name
        state.address = payload.address
        state.city = payload.city
        state.state = payload.state
        state.country = payload.country
        state.BackUpServer = payload.BackUpServer
    },
    setLoginStatus(state, payload) {
        state.login_status = payload
    },
    wait(state, payload) {
        state.wait = payload
    },
    SetBackUpServer(state, payload){
        state.BackUpServer = payload
    },
    setUserAdmin(state, payload){
        state.admin_user = payload
    },
    SetOnline(state, payload){
        state.online = payload
    },
    SetMockExam(state, payload){
        state.admin_info['mock_exam'] = payload
    }
}

const actions = {

}


const getters = {
    getLoginStatus(state){
        return state.login_status
    },
    wait(state){
        return state.wait
    },
    getCenter_id(state){
        return state.center_id
    },
    getCenter(state){
        return state
    },
    GetBackUpServer(state){
        return state.BackUpServer
    },
    getUserAdmin(state){
        return state.admin_user
    },
    GetAdminInfo(state){
        return state.admin_info
    },
    GetOnline(state){
        return state.online
    },
    GetMockExam(state){
        return state.admin_info['mock_exam']
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
