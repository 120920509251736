<template>
    <v-layout row wrap ma-3>
        <v-flex xs4 style="margin: 0px 10px;">
            <v-menu ref="menu" lazy :close-on-content-click="false" v-model="menu"
                    transition="scale-transition" offset-y full-width :nudge-right="40"
                    min-width="290px" :return-value.sync="date">
                <v-text-field slot="activator" label="Pick Exam Date"
                        v-model="date" prepend-icon="event" readonly></v-text-field>
                <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                    <v-btn flat color="primary"
                        @click="$refs.menu.save(date); GetExams(false)">OK</v-btn>
                </v-date-picker>
            </v-menu>
        </v-flex>
        <v-btn dark :disabled='!date' color='primary' @click="GetExams(true)">Refresh Exams</v-btn>
         <v-data-table hide-actions style="width: 100%;margin: 10px;border: 1px solid #d3d3d34a;" :headers="headers" :items="items" :search="search">
            <template slot="items" slot-scope="props">
                <td class="text-xs-left">{{ ( (pages - 1) * 5 ) + (props.index + 1) }}</td>
                <td class="text-xs-left">{{ props.item.exam_id }}</td>
                <td class="text-xs-left">{{ props.item.exam_name }}</td>
                <td class="text-xs-left">{{ props.item.start_date }}</td>
                <td class="text-xs-left">{{ props.item.start_time }}</td>
                <td class="text-xs-left">
                    <v-icon v-if="props.item.sync === true" class="cursor ma-1" medium color="green" title="Synced Exam">check_circle</v-icon>
                    <v-progress-circular indeterminate :size="20" color="primary"
                            v-else-if="inprogress.indexOf(props.item.exam_id + '-' + props.item.start_date + '-' + props.item.start_time) > -1">
                    </v-progress-circular>
                    <v-icon class="cursor ma-1" medium color="primary" @click="SyncExam(props.item)" v-else title="Sync Exam">file_download</v-icon>
                    <v-icon class="cursor ma-1" v-if="props.item.delay_exam == 0" medium color="primary" :title="`Delay Exam`" @click="DelayExamDialog(props.item)">alarm_add</v-icon>
                    <v-icon class="cursor ma-1" v-if="props.item.delay_exam == 1" medium color="grey" title="Requested Exam Delay" @click="VerifyDelayExam(props.item)">alarm</v-icon>
                    <v-icon class="cursor ma-1" v-if="props.item.delay_exam == 2" medium color="green" title="Exam Delay Approved" @click="VerifyDelayExam(props.item)">alarm_on</v-icon>
                    <v-icon class="cursor ma-1" v-if="props.item.delay_exam == 3" medium color="red" title="Exam Delay Rejected" @click="VerifyDelayExam(props.item)">alarm_off</v-icon>
                </td>
            </template>
        </v-data-table>
        <v-pagination v-if="records > 0" v-model="pages" :length="Math.ceil(records/5)" :total-visible="10" @input="GetExams(false)"></v-pagination>
        
        <v-dialog v-model="delay_dialog" max-width="600" persistent>
          <v-card>
                <v-card-title class="headline grey lighten-3" primary-title>
                    <v-flex>Delay Exam</v-flex>
                    <v-flex style="padding: 0px 20px;">
                        <v-icon @click="delay_dialog = false" style="float:right;">close</v-icon>
                    </v-flex>
                </v-card-title>
                <v-card-text>
                    <v-menu ref="menu1" lazy :close-on-content-click="false" v-model="menu1"
                        transition="scale-transition" offset-y full-width :nudge-right="40"
                        min-width="290px" :return-value.sync="date">
                        <v-text-field slot="activator" label="Pick Delay Exam Date"
                                v-model="delay_date" prepend-icon="event" readonly></v-text-field>
                        <v-date-picker v-model="delay_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn flat color="primary" @click="menu1 = false">Cancel</v-btn>
                            <v-btn flat color="primary"
                                @click="$refs.menu1.save(delay_date);">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                    <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                :return-value.sync="delay_exam_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="delay_exam_time" label="Delay Exam Time" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-time-picker v-if="menu2" v-model="delay_exam_time" full-width @click:minute="$refs.menu2.save(delay_exam_time)"></v-time-picker>
                    </v-menu>
                    <v-btn @click="DelayExam" color='primary' small> Delay Exam </v-btn>
                </v-card-text>
          </v-card>
      </v-dialog>
    </v-layout>
</template>
<script>

export default {
    components:{
    },
    data(){
        return {

            refresh : false,
            date    : new Date().toISOString().split('T')[0],
            search  : '',
            menu    : false,
            inprogress : [],
            headers    : [
                { text: 'S.No', value: false },
                { text: 'Exam ID', value: 'exam_id' },
                { text: 'Exam Name', value: 'exam_name' },
                { text: 'Date', value: 'start_date' },
                { text: 'Time', value:  'start_time'},
                { text: 'Actions', value:  false},
            ],
            items    : [],
            pages    : 1, 
            records  : 0,
            temp_date : "",
            delay_dialog : false,
            exam     : {},
            delay_date : new Date().toISOString().split('T')[0],
            delay_exam_time : "",
            menu1 : false,
            menu2 : false

        }
    },
    computed:{
        Center_Id(){
            return this.$store.getters.getCenter_id
        }
    },
    watch: { 
        date(){
            
        }
     },
    methods:{
        DelayExamDialog(exam){

            this.exam = exam;
            this.delay_dialog = true;

        },
        DelayExam(){

            this.$eventHub.$emit("LOADING",'true')
            if(this.delay_date && this.delay_exam_time ){
                let exam = { ...this.exam }
                exam['expected_start_date'] = this.delay_date
                exam['expected_start_time'] = this.delay_exam_time
                exam['expected_end_date'] = new Date(new Date().setDate(new Date( this.delay_date).getDate() + 1)).toISOString().split('T')[0]
                exam['expected_end_time'] = "23:55"
                this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/delay-exam`,exam)
                    .then(response => {
                        
                        if(response && response.data && response.data.response && Object.keys(response.data.response).length > 0){

                            let delay_status = response.data.response.delay_exam
                            let index = this.items.findIndex(x => x._id == response.data.response._id)
                            this.items[index]['delay_exam'] = delay_status;
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": 'Request For Delaying Exam Has Been Sent' ,"Color": `green`,"Close": false });
                            this.exam = {};
                            this.delay_dialog = false;

                        }else{

                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Error While Delay Request`,"Color":"red","Close": false });
                        }
                        
                        this.$eventHub.$emit("LOADING",'false')
                
                    })
                    .catch(error => {
                        
                        this.$eventHub.$emit("LOADING",'false')
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Error While Delaying Exam ${error}`,"Color":"red","Close": false });
                    })
            }else{
                
                this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Select Delay Date And Time.`,"Color":"red","Close": false });
                this.$eventHub.$emit("LOADING",'false')
            }
        },
        VerifyDelayExam(exam){

            this.$eventHub.$emit("LOADING",'true')
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/verify-delay-exam`,exam)
                .then(response => {
                    
                    if(response && response.data && response.data.response && Object.keys(response.data.response).length > 0){

                        let delay_status = response.data.response.delay_exam
                        let index = this.items.findIndex(x => x._id == response.data.response._id)
                        this.items[index]['delay_exam'] = delay_status;
                        let message = delay_status == 1 ? 'Requested Delay Status Has Not Been Changed' : delay_status == 2 ? 'Requested Delay Has Been Approved' : delay_status == 3 ? 'Requested Delay Has Been Declined' : "No Status Change"
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": message ,"Color": `${delay_status == 1 ? 'grey' : delay_status == 2 ? 'green' : delay_status == 3 ? 'red' : 'green'}`,"Close": false });

                    }else{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Error While Verifying Exam Delay`,"Color":"red","Close": false });
                    }
                    this.$eventHub.$emit("LOADING",'false')
                
                })
                .catch(error => {
                    
                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Error While Verifying Exam Delay`,"Color":"red","Close": false });
                })

        },
        GetExams(refresh){

            let data = { 'data' : {'center_id': this.Center_Id, refresh: refresh , start_date: this.date } }
            data['pagination'] =  { 'skip' : this.pages * 5 - 5  , 'limit' : 5 }
            this.$eventHub.$emit("LOADING",'true')
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/exam-list`,data)
                .then(response => {

                    this.$eventHub.$emit("LOADING",'false')
                    if(response && response['data'] && response['data']['data'] && response['data']['data'].length > 0){

                        this.items = response['data']['data']
                        this.records = response['data']['records']

                    }else{

                        this.items = []
                        this.records = 0
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "No Exams On This Date","Color":"red","Close": false });
                    }
                })
                .catch(error => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.items = []
                    this.records = 0
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Fetching Exam List","Color":"red","Close": false });
                })
        },
        SyncExam(exam){

            this.$eventHub.$emit("LOADING",'true')
            this.inprogress.push(exam.exam_id + '-'+exam.start_date + '-' + exam.start_time)
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/import-exam`, exam)
                .then(response =>{

                    this.$eventHub.$emit("LOADING",'false')
                    this.inprogress.splice(this.inprogress.indexOf(exam.exam_id + '-'+exam.start_date + '-' + exam.start_time), 1)
                    if(response && response['data']['response'] && response['data']['response']['sync']){

                        let index = this.items.findIndex(x => x['exam_id'] == response['data']['response']['exam_id'] && x['start_date'] == response['data']['response']['start_date'] && x['start_time'] == response['data']['response']['start_time'] )
                        index > -1 ? this.items[index]['sync'] = true : {}

                    }else{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Syncing Exam","Color":"red","Close": false });
                    }
                })
                .catch(error =>{
                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Syncing Exam","Color":"red","Close": false });
                })
        }
    },
    mounted(){

        this.$eventHub.$emit("LOADING",'false')
    }
}
</script>
<style scoped>

</style>