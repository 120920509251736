const state = {
    duration: 50,
    status: false
}

const mutations = {
    setMockDuration(state, payload){
        state.duration = payload
    },
    setMockStatus(state, payload){
        state.status = payload
    }
}

const actions = {
    someAsyncTask ({ commit }) {
        // do something async
        commit('INCREMENT_MAIN_COUNTER')
    }
}

const getters = {
    getMockDuration(state){
        return state.duration
    },
    getMockStatus(state){
        return state.status
    }
}


export default {
    state,
    mutations,
    actions,
    getters
}
