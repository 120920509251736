<template>
    <v-layout row wrap ma-3>
        <v-flex xs12 style="display: flex;">
            <v-flex style="margin: 0px 10px;">
                <v-menu ref="menu" lazy :close-on-content-click="false" v-model="menu"
                        transition="scale-transition" offset-y full-width :nudge-right="40"
                        min-width="290px" :return-value.sync="date">
                    <v-text-field slot="activator" label="Pick Exam Date"
                            v-model="date" prepend-icon="event" readonly></v-text-field>
                    <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn flat color="primary"
                            @click="$refs.menu.save(date); GetSessionList()">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>
            <v-flex style="margin: 0px 10px;">
                <v-select @change="SelectSession" v-model="Selected_Session" :items="Exam_Session" label="Select Session"></v-select>
            </v-flex>
        </v-flex>

    </v-layout>
</template>
<script>

import Util from '../util';
import Socket from '../socket/socket'
import _ from 'lodash'
import os from 'os'

export default {
    data(){
        return {
            date             : new Date().toISOString().split('T')[0],
            menu             : false,
            Selected_Session : "",
            Exam_Session     : []
        }
    },
    computed:{
        Center_Id(){
            return this.$store.getters.getCenter_id
        },
    },
    methods:{
        SelectSession(){

            let routeData = this.$router.resolve({ path: `/invigilate/${this.Center_Id}/${this.date}/${this.Selected_Session}` })
            window.open(routeData.href, '_blank');
        },
        GetSessionList(){

            let data = { "start_date" : this.date , 'center_id' : this.Center_Id }
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/exam-session`,data)
                .then((response) => {
                    
                    this.$eventHub.$emit("LOADING",'false')
                    if(response && response['data'] && response['data']['response'] && response['data']['response'].length > 0){

                        this.Exam_Session = response['data']['response']

                    }else{

                        this.Exam_Session = []
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "No Session Found On This Date / Try To Sync Exams List .","Color":"red","Close": false });
                    }

                })
                .catch((error) => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.Exam_Session = []
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Fetching Session List","Color":"red","Close": false });
                });
        }
    },
    mounted(){

        this.$eventHub.$emit("LOADING",'false')
    }
}
</script>
<style >
.iconMargin{
    margin: 0px;
}
li{
    list-style: none !important;
}
audio:focus {
    outline: none;
}
.NoRec{
    width: 10px;
	height: 10px;
	font-size: 0;
	background-color: #4169e1;
	border: 0;
	border-radius: 10px;
	margin: 18px;
	outline: none;
}
.Rec{
    width: 10px;
	height: 10px;
	font-size: 0;
	background-color: red;
	border: 0;
	border-radius: 10px;
	margin: 18px;
	outline: none;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes pulse{
	0%{
		box-shadow: 0px 0px 5px 0px rgba(173,0,0,.3);
	}
	65%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,.3);
	}
	90%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,0);
	}
}
</style>