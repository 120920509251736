<template>
    <v-app  class="app dashboard">
        <app-drawer class="app--drawer" :show-drawer="showDrawer"></app-drawer>
        <app-toolbar class="app--toolbar" :logout='false'></app-toolbar>
        <v-content>
        <div class="page-wrapper">
            <!-- <router-view></router-view>        -->
            <v-flex style="display: flex;height: calc( 100vh - 64px);">
                <v-flex xs6 ma-5 style="padding-top:60px;color:grey;">
                    <h2>Online And Offline Examination Solution For Everyone.</h2>
                    <h4>We re-engineered the service we built for secure examinations.</h4>
                    <br/>
                    <br/>
                    <br/>
                    <hr>
                    <br/>
                    <br/>
                    <a href="https://examin.co.in/" target="_blank">Learn more </a> about Examin
                </v-flex>
                <v-flex xs6 ma-5 style="color:grey;">
                    <v-flex class="circletag">
                        <img style="    width: 300px;
    height: 300px;
    margin-top: 21%;" :src="image" />
                    </v-flex>
                </v-flex>
            </v-flex>
        </div>
        </v-content>
    </v-app>
</template>
<script>
import AppDrawer from "@/components/AppDrawer"
import AppToolbar from "@/components/AppToolbar"
import AppFab from "@/components/AppFab"
import PageHeader from "@/components/PageHeader"
export default {
    components: {
        AppDrawer,
        AppToolbar,
        AppFab,
        PageHeader
    },

    data(){
        return {
            showDrawer: false
        }
    },
    computed:{
        image(){
            return require('../assets/image11.jpg')
        }
    },
    mounted(){
        this.$eventHub.$emit("LOADING",'false')
    }
}
</script>
<style >
div.circletag {
    display: block;
    width: 500px;
    height: 500px;
    background: #E6E7ED;
    text-align: center;
    align-content: center;
    align-items: center;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
}
div.circletag>img {
   max-height: 100%;
   max-width: 100%;

}
</style>