<template>
    <v-flex ma-3>
        <v-flex xs12 style="display: flex;">
            <v-flex style="margin: 0px 10px;">
                <v-menu ref="menu" lazy :close-on-content-click="false" v-model="menu"
                        transition="scale-transition" offset-y full-width :nudge-right="40"
                        min-width="290px" :return-value.sync="date">
                    <v-text-field slot="activator" label="Pick Exam Date"
                            v-model="date" prepend-icon="event" readonly></v-text-field>
                    <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn flat color="primary"
                            @click="$refs.menu.save(date); GetSessionList()">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>
            <v-flex style="margin: 0px 10px;">
                <v-select v-model="Selected_Session" :items="Exam_Session" label="Select Session"></v-select>
            </v-flex>
            <v-btn color="primary" @click="SelectSession">Generate Responses</v-btn>
            <v-btn color="primary" @click="GenerateAttendance">Generate Attendance</v-btn>
            <v-btn color="primary" @click="GenerateOnExam">Generate On Exam Id</v-btn>
        </v-flex>
        <v-divider></v-divider>
        <template v-if="Exam_Information && Exam_Information.length > 0">
            <v-flex style="display:flex;">
                <section v-for="information in Exam_Information" :key="information.exam_id +'_Button'" >
                    <v-btn @click="ChangeTable(information)" :color="Selected_Exam == information.exam_name ? 'primary' : ''" >{{ information.exam_name }} ( {{ information.exam_id }}) </v-btn>
                </section>
            </v-flex>
            <v-flex style="margin: 10px 5px; border: 1px solid lightgray;">
                <v-flex :class="index != 0 ? 'hidden' : ''" :id="information.exam_id +'_Table'" style="overflow:scroll;" v-for="( information,index ) in Exam_Information" :key="information.exam_id + '_' + index">
                    <v-flex style="display: flex;">
                        <v-flex style="width: 120px;display: inline-flex;" v-for="Subject in information['Subjects']" :key="Subject._id">
                            <v-flex :style="{ 'background' : Subject.color ,'height' : '10px' , 'width': '10px' , 'margin': '4px' }"></v-flex> 
                            <v-flex>{{ Subject.name }}</v-flex>
                        </v-flex>
                    </v-flex>
                    <v-flex style="display: flex;">
                        <v-flex :style="{ 'background': item.color ? item.color : '#ffffff' , 'color': item.color ? 'white' : '#000000' }" class="clsForTr" v-for="(item,i) in information['Headers']" :key="item.key + '_' + i">{{ item.text }} {{ item.value == 'last_name' || item.value == 'first_name' || item.value == 'email' ? ' ' : `( ${ item.value } )` }} </v-flex>
                    </v-flex>
                    <section v-if="information['Students'] && information['Students'].length > 0">
                        <v-flex style="display: flex;" v-for="(student,index) in information['Students']" :key="index + '_' + information.exam_id">
                            <v-flex :style="{ 'background': item.color ? item.color : '#ffffff' , 'color': item.color ? 'white' : '#000000' }" class="clsForTr" v-for="(item,i) in student" :key="i + '_'+ index + '_' + information.exam_id + '_'+ item.text">{{ item.text ? item.text : '-' }}</v-flex>
                        </v-flex>
                    </section>
                    <section v-else style="margin: 12px;text-align: center;font-size: 16px;font-weight: bold;">
                        No Student Responses For This Exam
                    </section>
                </v-flex>
            </v-flex>
        </template>
        <app-attendance :dialog='dialog' @close='CloseDialog'></app-attendance>
    </v-flex>
</template>
<script>

import Util from '../util';
import Socket from '../socket/socket'
import _ from 'lodash'
import appAttendance from './dialogs/Attendance'

export default {
    components:{
        appAttendance
    },
    data(){
        return {
            date             : new Date().toISOString().split('T')[0],
            menu             : false,
            Selected_Session : "",
            Exam_Session     : [],
            Exam_Information : [],
            Selected_Exam    : "",
            dialog           : false
        }
    },
    computed:{
        Center_Id(){
            return this.$store.getters.getCenter_id
        },
        GetCenter(){
            return this.$store.getters.getCenter
        }

    },
    methods:{
        CloseDialog(){
            this.dialog = false
        },
        GenerateOnExam(){
            this.dialog = true
        },
        GenerateAttendance(){

            let _this = this
            let data = { 'date' : this.date  ,'center_name' : `${this.GetCenter['center_name']}(${this.Center_Id})` , type : "Date" }
            this.Selected_Session ? data['time'] = this.Selected_Session : {}
            this.$eventHub.$emit("LOADING",'true')
            this.$http.post(`${this.$store.getters.GetProductionUrl}/student/attendance`,data,{ responseType: "blob" })
                .then((response) => {
                    
                    if(response && response.data){

                        this.$eventHub.$emit("LOADING",'false')
                        var file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        const objectUrl = window.URL.createObjectURL(file)
                        var anchor = document.createElement("a");
                        anchor.download = `${_this.date}${_this.Selected_Session ? `_${_this.Selected_Session}` : ""}.xlsx`;
                        anchor.href = objectUrl;
                        anchor.click();
                    
                    }else{

                         this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Downloading Atttendance List","Color":"red","Close": false });
                    }   
                    this.$eventHub.$emit("LOADING",'false')
                })
                .catch((error) => {
        
                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Fetching Atttendance List","Color":"red","Close": false });
                
                });
        },
        ChangeTable(exam){

            this.Selected_Exam = exam.exam_name
            document.getElementById(`${exam.exam_id}_Table`).classList.remove("hidden");
            let addClass = this.Exam_Information.map(x => x.exam_id != exam.exam_id ? x.exam_id : '').filter(x => x != "")
            addClass.forEach(element => {                
                document.getElementById(`${element}_Table`).classList.add("hidden")
            });
        },
        SelectSession(){

            this.$eventHub.$emit("LOADING",'true')
            let data = {'center_id': this.Center_Id, 'start_date': this.date , "start_time" : this.Selected_Session}
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/response-list`,data)
                .then((response) => {
                    
                    this.$eventHub.$emit("LOADING",'false')
                    this.Exam_Information = response['data']['response']
                    this.Selected_Exam = this.Exam_Information && this.Exam_Information.length > 0 ? this.Exam_Information[0]['exam_name'] : ''
                    console.log(this.Exam_Information[0])
                })
                .catch((error) => {
        
                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Fetching Session List","Color":"red","Close": false });
                
                });

        },
        GetSessionList(){

            let data = { "start_date" : this.date , 'center_id' : this.Center_Id }
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/exam-session`,data)
                .then((response) => {
                    
                    this.$eventHub.$emit("LOADING",'false')
                    if(response && response['data'] && response['data']['response'] && response['data']['response'].length > 0){

                        this.Exam_Session = response['data']['response']

                    }else{

                        this.Exam_Session = []
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "No Session Found On This Date / Try To Sync Exams List .","Color":"red","Close": false });
                    }

                })
                .catch((error) => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.Exam_Session = []
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Fetching Session List","Color":"red","Close": false });
                });
        }
    },
    mounted(){

        this.$eventHub.$emit("LOADING",'false')    
    }
}
</script>
<style scoped>
.clsForTr{
    padding: 6px 0px;
    min-width: 130px;
    margin: 1px;
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
}
.hidden {
    display: none !important;
}
</style>