const Menu = [
  {
    title: "Dashboard",
    group: "apps",
    icon: "assessment",
    name: "Dashboard",
    visibility : true
  },
  {
    title: "Exams",
    group: "apps",
    icon: "article",
    name: "Exams",
    visibility : true
  },
  {
    title: "Students",
    group: "apps",
    icon: "people",
    name: "Students",
    visibility : true
  },
  {
    title: "Systems",
    group: "apps",
    icon: "settings_system_daydream",
    name: "Systems",
    visibility : true
  },
  {
    title: "Invigilation",
    group: "apps",
    icon: "supervised_user_circle",
    name: "Invigilation",
    visibility : process.env.VUE_APP_BUILD_TYPE == 'Web'
  },
  {
    title: "Service",
    group: "apps",
    icon: "settings",
    name: "Service",
    visibility : process.env.VUE_APP_BUILD_TYPE == 'Web'
  },
  {
    title: "Users",
    group: "apps",
    icon: "people",
    name: "Users",
    visibility : process.env.VUE_APP_BUILD_TYPE == 'Web'
  },
  {
    title: "Attendance",
    group: "apps",
    icon: "article",
    name: "Attendance",
    visibility : true
  },
  {
    title: "Client Logs",
    group: "apps",
    icon: "list",
    name: "Client Logs",
    visibility : true
  },
]
// reorder menu
Menu.forEach(item => {
  if (item.items) {
    item.items.sort((x, y) => {
      let textA = x.title.toUpperCase()
      let textB = y.title.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })
  }
})

export default Menu