<template>
    <v-layout wrap row>
        <v-flex xs5 pa-5 style="border-right: 1px solid #c4c4c4;">
            <v-flex> <b> Select Operating System : </b></v-flex>
            <v-radio-group v-model="Operating_System" row>
                <v-radio v-for="n in Operating_System_List" :key="n" :label="n" :value="n" ></v-radio>
            </v-radio-group>
            <template v-if="Operating_System == 'Windows'">
                <v-flex> <b> Select Command Type : </b> </v-flex>
                <v-radio-group v-model="Command_Type" row>
                    <v-radio v-for="m in Command_Type_List" :key="m" :label="`${m}`" :value="m"></v-radio>
                </v-radio-group>
            </template>
            <v-text-field v-model="Application" label='Application Name'></v-text-field>
            <v-text-field v-model="Command" label='Command'></v-text-field>
            <v-btn @click="SaveCommand" color='primary' v-if="!Update">Create Command</v-btn>
            <v-btn @click="UpdateCommandDB" color='primary' v-else>Update Command</v-btn>
            <v-btn @click="ClearForm">Clear</v-btn>
        </v-flex>
        <v-flex xs7 pa-2>
            <v-data-table :headers="Headers" :items="Command_List" hide-actions disable-initial-sort height="inherit" item-key="_id">
                
                <template v-slot:headers="props">
                    <tr>
                    <th v-for="header in props.headers" :key="header.text">
                        {{ header.text }}
                    </th>
                    </tr>
                </template>
                <template v-slot:items="props">
                    <tr>
                    <td class="text-xs-center">{{ props.item.OS }}</td>
                    <td class="text-xs-center">{{ props.item.Application }}</td>
                    <td class="text-xs-center">{{ props.item.Command }}</td>
                    <td class="text-xs-center">{{ props.item.Command_Type }}</td>
                    <td class="text-xs-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" color="primary" fab small dark @click="UpdateCommand(props.item)">
                                <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit Command</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" color="error" fab small dark @click="DeleteCommand(props.item)">
                                <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Command</span>
                        </v-tooltip>
                    </td>
                    </tr>
                </template>
            </v-data-table>
            <v-pagination v-if="records > 0" v-model="pages" :length="Math.ceil(records/10)" :total-visible="10" @input="GetListData"></v-pagination>
        </v-flex>
    </v-layout>
</template>
<script>
export default {
    data(){
        return {
            Application  : "",
            Command      : "",
            Command_Type : "Process",
            Operating_System      : "Windows",
            Command_Type_List     : ['Process','Service'],
            Operating_System_List : ['Windows','Ubuntu','Mac'],
            Command_List          : [],
            Headers               : [
                { text: 'OS' , value : "OS"},
                { text: "Application", value : "Application"},
                { text: "Command", value : "Command"},
                { text: "Command Type", value : "Command_Type"},
                { text: "Actions" }
            ],
            Command_Id : "",
            pages      : 1, 
            records    : 0,
            Update     : false

        }
    },
    computed:{

    },
    methods:{
        UpdateCommand(command){
            this.Command_Id   = command['_id'],
            this.Application  = command['Application']
            this.Command      = command['Command']
            this.Command_Type = command['Command_Type']
            this.Operating_System  = command['OS']
            this.Update = true
        },
        UpdateCommandDB(){

            this.$eventHub.$emit("LOADING",'true')
            let data = { '_id' : this.Command_Id}
            data['Update'] = {
                Command : this.Command,
                Command_Type : this.Command_Type,
                OS : this.Operating_System,
                Application : this.Application
            }
            this.$http.post(`${this.$store.getters.GetProductionUrl}/settings/update-command-list`,data)
                .then((response) => {
                    
                    this.$eventHub.$emit("LOADING",'false')
                    if(response['data'] && response['data']['response']){

                        let index = this.Command_List.findIndex(x => x['_id'] == response['data']['response']['_id'])
                        this.Command_List.splice(index,1,response['data']['response'])
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Successfuly Updated Command","Color":"green","Close": false });
                    
                    }else{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Cannot Update Try Again Later","Color":"red","Close": false });
                    }

                })
                .catch((error) => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Deleting Command","Color":"red","Close": false });
                });

        },
        DeleteCommand(command){

            this.$eventHub.$emit("LOADING",'true')
            this.$http.post(`${this.$store.getters.GetProductionUrl}/settings/delete-command-list`,command)
                .then((response) => {
                    
                    this.$eventHub.$emit("LOADING",'false')
                    if(response['data'] && response['data']['response']){

                        let index = this.Command_List.findIndex(x => x['_id'] == response['data']['response']['_id'])
                        this.Command_List.splice(index,1)
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Successfuly Delete Command","Color":"green","Close": false });
                    }else{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Cannot Delete Try Again Later","Color":"red","Close": false });
                    }

                })
                .catch((error) => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Deleting Command","Color":"red","Close": false });
                });

        },
        ClearForm(){
            this.Application  = ""
            this.Command      = ""
            this.Command_Type = "Process"
            this.Operating_System  = "Windows"
            this.Command_Id = ""
            this.Update = false
        },
        SaveCommand(){

            let data = {
                Command : this.Command,
                Command_Type : this.Command_Type,
                OS : this.Operating_System,
                Application : this.Application
            }
            this.$eventHub.$emit("LOADING",'true')
            this.$http.post(`${this.$store.getters.GetProductionUrl}/settings/create-command-list`,data)
                .then((response) => {
                    
                    this.$eventHub.$emit("LOADING",'false')
                    if(response && response['data'] && response['data']['response']){
                        
                        this.Command_List.unshift(response['data']['response'])
                        this.ClearForm()

                    }else{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Cannot Create the Command","Color":"red","Close": false });
                    }

                })
                .catch((error) => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Creating Command","Color":"red","Close": false });
                });

        },
        GetListData(){

            let data = {}
            data['pagination'] =  { 'skip' : this.pages * 10 - 10  , 'limit' : 10 }
            this.$http.post(`${this.$store.getters.GetProductionUrl}/settings/get-command-list`,data)
                    .then((response) => {
                        
                        this.$eventHub.$emit("LOADING",'false')
                        if(response && response['data'] && response['data']['response'] && response['data']['response'].length > 0){
                            
                            this.Command_List = response['data']['response']
                            this.records = response['data']['Records']
    
                        }else{
    
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "No Command List Found","Color":"red","Close": false });
                        }
    
                    })
                    .catch((error) => {
    
                        this.$eventHub.$emit("LOADING",'false')
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Creating Command","Color":"red","Close": false });
                    });
        }
    },
    mounted(){

        this.GetListData()
    }
}
</script>
<style scoped>

</style>