<template>
    <v-layout row wrap>
        <v-card>
            <v-tabs background-color="primary" class="elevation-2" dark grow show-arrows>
                <v-tab v-for="i in Tabs_Count" :key="i" :href="`#tab-${i}`"> 
                    Tab {{ i }}
                </v-tab>
                <v-tab-item v-for="i in Tabs_Count" :key="i" :value="'tab-' + i" >

                    <v-card v-for="(student,index) in Students[i-1]" :key="student.id" style="width: 24%;display: inline-block;margin: 6px">
                            
                        <vueper-slides :ref="`studentrefs_${index}`" :slide-ratio="1 / 2" fixed-height="180px" :bullets="false">
                            <vueper-slide v-for="(image,index) in SortArray(student.images)" :key="index">
                                <template v-slot:content>
                                    <img style="width:100%;height:100%;" :src="URL + image.Image">
                                    <v-flex style="position: absolute;top: 5px;right: 9px;color: red;font-weight: bold;">{{ new Date(image.Updated_At).toISOString().split('T')[0] }} , {{ new Date(image.Updated_At).toLocaleTimeString() }}</v-flex>
                                </template>
                            </vueper-slide>
                        </vueper-slides>
                        <v-flex style="display:flex;">
                            <v-btn v-if="student.Online" class="mx-2" fab dark small color="error">
                                <v-icon dark @click="WarnStudent(student)">warning</v-icon>
                            </v-btn>
                            <div v-else style="height: 35px;width: 35px;" class="mx-2"></div>
                            <v-flex xs5 style="margin: 6px;">
                                <v-flex style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ student.first_name }} </v-flex>
                                <v-flex style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ student.last_name }}  </v-flex>
                            </v-flex>
                            <v-flex style="margin: 12px 3px;">
                                <v-btn @click="RecordScreen(student)" class="iconMargin" small color="error" fab dark flat>
                                    <v-icon v-if="student.Online">fiber_manual_record</v-icon>
                                </v-btn>
                                <v-btn @click="LockTheScreen(student)" class="iconMargin" small :color="student.screen_lock ? 'error' : 'primary' " fab dark flat>
                                    <v-icon v-if="student.screen_lock">lock</v-icon>
                                    <v-icon v-else>lock_open</v-icon>
                                </v-btn>
                                <v-btn class="iconMargin" small :color="student.Online ? 'primary' : 'error'" fab dark flat>
                                    <v-icon v-if="student.Online">wifi</v-icon>
                                    <v-icon v-else>wifi_off</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-flex>
                    </v-card>
                </v-tab-item>
            </v-tabs>               
        </v-card>

        <v-bottom-sheet v-model="sheet" inset persistent>
            <v-sheet class="text-center" height="100px">
                <v-flex xs6 style="display: flex;margin: auto;padding: 25px 5px;">
                    <v-text-field v-model="Message" :label='`Notify ${Current_User.last_name}`'></v-text-field>
                    <v-btn color='primary' @click="SendNotification">Send Notification</v-btn>
                    <v-icon style="position: absolute;top: 14px;right: 25px;" @click="ClearAndClose">close</v-icon>
                </v-flex>
            </v-sheet>
        </v-bottom-sheet>

         <v-bottom-sheet v-model="Record_Sheet" inset persistent>
            <v-sheet class="text-center" height="100px">
                <v-flex xs10 style="display: flex;margin: auto;padding: 25px 5px;">
                    <div v-if="Recording && !Recorded" class="Rec"></div>
                    <div v-else-if="!Recording && !Recorded" class="NoRec"></div>
                    <v-btn @click="StartRecording" v-if="!Recording && !Recorded" color='primary'> Start Recording </v-btn>
                    <v-btn @click="StopRecording" v-else-if="Recording && !Recorded" color='error'> Stop Recording </v-btn>
                    <audio v-if="!Recorded" controls></audio>
                    <ul id="playlist"></ul>
                    <v-btn @click="SendRecording" v-if="Recorded" color='primary'> Send Recording To {{ Current_User.last_name}}</v-btn>
                    <v-btn v-if="Recorded" flat @click="ClearRecording" color='error'> Clear Recording </v-btn>
                    <v-icon style="position: absolute;top: 38%;right: 25px;" @click="ClearRecording();ClearAndClose();">close</v-icon>
                </v-flex>
            </v-sheet>
        </v-bottom-sheet>
        
    </v-layout>
</template>
<script>

import Util from '../util';
import Socket from '../socket/socket'
import _ from 'lodash'
import os from 'os'
// const MicRecorder = require('mic-recorder-to-mp3');
// const recorder = new MicRecorder({ bitRate: 128 });
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import util from '../util';

let interval_invigilation = null;

export default {
    components: { VueperSlides, VueperSlide },
    data(){
        return {
            dialog           : false,   
            Message          : '',
            sheet            : false,
            Current_User     : {},
            Total_Records    : 0,
            Tabs_Count       : 0,
            Record_Sheet     : false,
            Recording        : false,
            Recorded         : false,
            File             : null,
            Center_Id        : null,

        }
    },
    computed:{
        Students(){
           return _.chunk(this.$store.getters.GetNewStudents, 12)
        },
        URL(){
            return this.$store.getters.GetProductionUrl
        }
    },
    methods:{
        ClearAndClose(){
            this.Current_User = {}
            // var list = document.getElementById("playlist");
            // list.removeChild(list.childNodes[0]);
            this.File = null
            this.Recorded = false;
            this.Recording = false;
            this.Record_Sheet = false;
            this.Message = '',
            this.sheet = false
        },
        ClearRecording(){
            var list = document.getElementById("playlist");
            list.removeChild(list.childNodes[0]);
            this.File = null
            this.Recorded = false;
            // this.ClearAndClose()
        },
        RecordScreen(Student){
            this.Record_Sheet = true
            this.Current_User = Student
        },
        StartRecording(){

        //    recorder.start().then(() => {
              
        //       this.Recording = true;
        //       this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Audio Recording Started","Color":"green","Close": false });
        //   })
        //   .catch((e) => {

        //       this.Recording = false
        //       this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Failed To Record Audio ","Color":"red","Close": false });
        //   });

         },
         StopRecording(){

            // recorder.stop().getMp3()
            //       .then(([buffer, blob]) => {
                    
            //         this.Recorded = true;
            //         this.File = new File(buffer, 'music.mp3', { type: blob.type, lastModified: Date.now() });
            //         const li = document.createElement('li');
            //         const player = new Audio(URL.createObjectURL(this.File));
            //         player.controls = true;
            //         li.appendChild(player);
            //         document.querySelector('#playlist').appendChild(li);
            //         this.Recording = false;
 
            //     })
            //     .catch((e) => {

            //         this.Recording = false;
            //         this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Failed To Record Audio ","Color":"red","Close": false });
            //     });
        },
        SendRecording(){

            this.$eventHub.$emit("LOADING",'true')
            const fd = new FormData();
            fd.append('Recording', this.File,this.File.name);
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/upload-file`,fd)
                .then(response =>{
                    
                    if(response && response['data'] && response['data']['response'] && response['data']['response']['path']){

                        let student_info = { ...this.Current_User , Record_URL : `${this.$store.getters.GetProductionUrl}/${response['data']['response']['path']} `}
                        Socket.SendAudio(student_info);
                        this.$eventHub.$emit("LOADING",'false')
                        this.ClearRecording()
                        this.ClearAndClose()
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Audio Sent Successfully","Color":"green","Close": false });

                    }else{

                        this.$eventHub.$emit("LOADING",'false')
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Failed To Send Audio ","Color":"red","Close": false });
                    }
                })
                .catch(error =>{

                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Creating Audio","Color":"red","Close": false });
                })
        },
        LockTheScreen(student){
            let data = { ...student }
            data['screen_lock'] = !student['screen_lock']
            Socket.LockScreen(data)
        },
        SendNotification(){
            Socket.SendNotification({"Message" : this.Message , ...this.Current_User })
            this.ClearAndClose()
        },
        WarnStudent(Student){
            this.Current_User = Student
            this.sheet = true;
        },
        SortArray(Images){
            return _.orderBy(Images, ['Updated_At'], ['desc'])
        },
        SelectSession(){

            this.$eventHub.$emit("LOADING",'true')
            let data = { 'data' : {'center_id': this.Center_Id, 'date': new Date(this.date + ' ' + this.Selected_Session) } }
            data['pagination'] = {}
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/session-students`,data)
                .then((response) => {
                    
                    this.$eventHub.$emit("LOADING",'false')
                    if(response && response['data'] && response['data']['response'] && response['data']['response'].length > 0){

                        this.Total_Records = response['data']['response'].length
                        this.Tabs_Count = Math.ceil(this.Total_Records/12)
                        Socket.attemptGetOnlineUsers(response['data']['response'],{})
                        // util.toggleFullScreen();

                    }else{
            
                        this.$store.commit('SetNewStudents',[])
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "No Students Found On This Date / Try To Sync Exams List .","Color":"red","Close": false });
                    }

                })
                .catch((error) => {
        
                    this.$store.commit('SetNewStudents',[])
                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Fetching Session List","Color":"red","Close": false });
                });

        },
    },
    destroyed() {
        clearInterval(interval_invigilation);
    },
    mounted(){

        this.$eventHub.$emit("LOADING",'false')
        this.date = this.$route.params.date
        this.Selected_Session = this.$route.params.session
        this.Center_Id = this.$route.params.center
        this.SelectSession()
        let _this = this
        interval_invigilation = setInterval(function() {
            // _this.$store.getters.GetNewStudents.length > 0 ?  _this.$store.commit('UpdateScreenShot' , data ) : {}
            for(var i = 0 ; i< _this.$store.getters.GetNewStudents.length ; i++){
                _this.$refs && _this.$refs[`studentrefs_${i}`] && _this.$refs[`studentrefs_${i}`][0] ? _this.$refs[`studentrefs_${i}`][0].goToSlide(0) : {}
            }
        },60000)
   
   }
}
</script>
<style >
.iconMargin{
    margin: 0px;
}
li{
    list-style: none !important;
}
audio:focus {
    outline: none;
}
.NoRec{
    width: 10px;
	height: 10px;
	font-size: 0;
	background-color: #4169e1;
	border: 0;
	border-radius: 10px;
	margin: 18px;
	outline: none;
}
.Rec{
    width: 10px;
	height: 10px;
	font-size: 0;
	background-color: red;
	border: 0;
	border-radius: 10px;
	margin: 18px;
	outline: none;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes pulse{
	0%{
		box-shadow: 0px 0px 5px 0px rgba(173,0,0,.3);
	}
	65%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,.3);
	}
	90%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,0);
	}
}
</style>