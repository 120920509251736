import Student from './Student'

const state = {
    success: null,
    exams: [],
    error: false,
    runningExams: [],
    date: null,
    current_session : {},
    current_exam_details : {},
    Client_Logs : []
}

const mutations = {
    setExams(state, payload){
        state.exams = payload;
    },
    setSyncStatus(state, payload){

        let index = state.exams.findIndex(function (exam) {
            return exam.exam_id === payload.exam_id && exam.start_date === payload.start_date
                                    && exam.start_time === payload.start_time
        })

        let exam = state.exams[index]

        exam.sync = payload.status

        state.exams.splice(index, 1, exam)

    },
    setError(state, payload){
        state.error = payload
    },
    setRunningExam(state, payload){
        console.log(payload);
        console.log("running")
        let index = state.runningExams.findIndex((exam) => {
            return exam.exam_id === payload.exam_id
        })

        if(index === -1) {
            state.runningExams.push(payload)
        }
        for(let i=0;i<state.exams.length;i++){
            if(state.exams[i]["exam_id"] === payload.exam_id && state.exams[i]["start_time"] === payload.session_time){
                if(state.current_session["start_time"] == undefined || state.current_session["start_time"] !== state.exams[i]["start_time"]){
                    state.current_session["start_time"]  = state.exams[i]["start_time"];
                    Student.state.current_session        = state.exams[i]["start_time"];
                    Student.state.current_session_submit = 0;
                }
            }
        }
    },
    setExamSyncDate(state, payload){
        state.date = payload
    },
    setCurrentExam(state,payload){
        state.current_exam_details = payload
    },
    setLogs(state,payload){
        state.Client_Logs = payload
    },
    setIndividualLog(state,payload){
        state.Client_Logs.push(payload)
    }
}

const actions = {

}

const getters = {
    getExams(state){
        return state.exams
    },
    error(state){
        return state.error
    },
    getRunningExams(state){
        return state.runningExams
    },
    getExamSyncDate(state){
        return state.date
    },
    getCurrentExam(state){
        return state.current_exam_details
    },
    getLogs(state){
        return state.Client_Logs
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
