<template>
  <v-card class="elevation-1 pa-3 login-card">
    <v-card-text>
      <div class="layout column align-center">
        <h1 class="flex my-4 primary--text">Invigilator Login</h1>
      </div>
      <v-form>
        <v-text-field append-icon="email" name="Email" label="Email" v-model="Email"></v-text-field>
        <v-text-field append-icon="lock" name="Password" label="Password" type="password" v-model="Password"></v-text-field>
      </v-form>
    </v-card-text>
    <div class="login-btn">
      <v-spacer></v-spacer>
      <v-btn block color="primary" @click="Login">Login</v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  data(){
    return {

        loading      : false,
        Email        : "krahulreddy13@gmail.com",
        Password     : "12345"
      }
  },
  methods: {

    Login() {
      
        let data = { 'Email': this.Email, 'Password': this.Password }
        this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/verify-user`,data)
            .then((response) => {
                 
                let Response = response && response.data && response.data.response ? response.data.response : {};
                if(Object.keys(Response).length > 0){

                  this.$store.commit('setUserAdmin', Response)
                  var d = new Date()
                  d.setTime(d.getTime() + (7*24*60*60*1000));
                  document.cookie = `Uemail=${Response.Email};expires=${d.toUTCString()}`
                  document.cookie = `Upassword=${Response.Password};expires=${d.toUTCString()}`
                  this.$http.defaults.headers.common['Authorization'] = `email=${Response.Email};password=${Response.Password}`
                  this.$router.push('/dashboard');
                }else{

                  console.log(Response)

                }

            }).catch((err) => {

                console.log(err)              
            });
           
    }
  },
  mounted(){
    
    this.$eventHub.$emit("LOADING",'false');
    
  }
}
</script>
<style scoped lang="css"></style>
