import Vue from "vue"
import "./plugins/vuetify"
import App from "./App.vue"
import router from "./router/"
import store from "./store"
import "roboto-fontface/css/roboto/roboto-fontface.css"
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import "font-awesome/css/font-awesome.css"
import axios from 'axios'
import VueGoogleCharts from 'vue-google-charts'
import StarRating from 'vue-star-rating'
import Pagination    from 'vue-pagination-2';
import Socket from "./socket/socket";
import io from 'socket.io-client';

Vue.prototype.$Socket = Socket

axios.defaults.withCredentials = true;
Vue.prototype.$http = axios
Vue.prototype.$http.defaults.headers.common['Authorization'] = process.env.IS_ELECTRON ? GetCookieStorage('token') : GetCookie('token')

function GetCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

function GetCookieStorage(cname) {

  return localStorage.getItem(cname) ? localStorage.getItem(cname) : "";

 }

Vue.config.productionTip = false

Vue.use(require('vue-moment'));
Vue.component('star-rating', StarRating);
Vue.component('pagination', Pagination);
Vue.use(VueGoogleCharts)
Vue.prototype.$eventHub = new Vue()

Vue.mixin({
  methods: {
      convertText(value){
          let txt = document.createElement("textarea");
          txt.innerHTML = value;
          return txt.value;
      }
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
  created(){ 

      let URL = this.$store.getters.GetProductionUrl + '/admin'
      this.$Socket.close();
      let socket = io(URL)
      this.$Socket.init(socket);
      
      this.$eventHub.$emit("LOADING",'true')
      this.$http.get(`${store.getters.GetProductionUrl}/admin/verify`)
              .then(response =>{

                  let admin = response.data && response['data']['response'] ? response['data']['response'] : null;
                  let session = response.data && response['data']['session'] ? response['data']['session'] : null;
                  if(admin && session){
                     
                    process.env.VUE_APP_BUILD_TYPE != 'Web' ? this.$Socket.SetEnableBackup() : {}
                    session.user_type ?  store.commit('setAdmin', admin) : store.commit('setUserAdmin', admin);

                  }else{

                  }
              })
              .catch(error =>{

                  console.log(error);

               })

  }

}).$mount("#app")