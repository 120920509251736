<template>
    <v-layout wrap row style="min-height: calc(81vh - 50px);">
         <v-flex xs5 pa-5 style="border-right: 1px solid #c4c4c4;">
             <v-text-field v-model="Email" label='Email'></v-text-field>
             <v-text-field v-model="Password" label='Password'></v-text-field>
             <v-text-field v-model="Priority" label='Priority' type='number' min="0"></v-text-field>
             <!-- <v-flex style="display: flex;">
                <v-text-field v-model="Max_Limit" class="ma-2" label='Max Student Count' type='number' min="0"></v-text-field>
             </v-flex> -->
             <v-btn @click="Clear" style="float: right;" flat color='primary'>Clear</v-btn>
             <v-btn v-if="!Update" @click="CreateUser" style="float: right;" color='primary'>Create User</v-btn>
             <v-btn v-else @click="UpdateUserInDB" style="float: right;" color='primary'>Update User</v-btn>
         </v-flex>
         <v-flex xs7 pa-2>
             <v-data-table :headers="Headers" :items="items" hide-actions disable-initial-sort height="inherit" item-key="_id">
                
                <template v-slot:headers="props">
                    <tr>
                    <th v-for="header in props.headers" :key="header.text">
                        {{ header.text }}
                    </th>
                    </tr>
                </template>
                <template v-slot:items="props">
                    <tr>
                    <td class="text-xs-center">{{ props.item.Email }}</td>
                    <td class="text-xs-center">{{ props.item.Password }}</td>
                    <td class="text-xs-center">{{ props.item.Priority }}</td>
                    <td class="text-xs-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" color="primary" fab small dark @click="UpdateUser(props.item)">
                                <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit User</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" color="error" fab small dark @click="DeleteUser(props.item)">
                                <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete User</span>
                        </v-tooltip>
                    </td>
                    </tr>
                </template>
            </v-data-table>
         </v-flex>
    </v-layout>
</template>
<script>
export default {
    data(){
        return {
            Email     : '',
            Password  : '',
            Priority : 0,
            Max_Limit : 0,
            items     : [],
            Headers   : [
                { text: 'Email' , value : "Email"},
                { text: "Password", value : "Password"},
                { text: "Priority", value : "Priority"},
                { text: "Actions" },
            ],
            User_Id   : '',
            Update    : false
        }
    },
    methods:{
        Clear(){
            this.Email    = ''
            this.Password = ''
            this.User_Id  = ''
            this.Priority = 0
            this.Update   = false
        },
        CreateUser(){

            let data = {
                Email     : this.Email,
                Password  : this.Password,
                Priority  : parseInt(this.Priority),
            }
            let Check_Priority = this.items.findIndex(x => x.Priority == parseInt(this.Priority))
            if(this.Email && this.Password && parseInt(this.Priority) > 0 && Check_Priority == -1){

                this.$eventHub.$emit("LOADING",'true')
                this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/create-user`,data)
                    .then(response => {
    
                        this.$eventHub.$emit("LOADING",'false')
                        if(response && response['data'] && response['data']['response']){
    
                            this.items.push(response['data']['response'])
                            this.Clear()
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "User Created Successfully","Color":"green","Close": false });
    
                        }else{
    
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "User Not Created","Color":"red","Close": false });
                        }
                    })
                    .catch(error => {
    
                        this.$eventHub.$emit("LOADING",'false')
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Creating User","Color":"red","Close": false });
                    })
            }else{
                
                this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Fill the Correct Details","Color":"red","Close": false });
            }

        },
        UpdateUser(Student){
            this.Email     = Student['Email']
            this.Password  = Student['Password']
            this.User_Id   = Student['_id']
            this.Priority  = Student['Priority']
            this.Update    = true
        },
        UpdateUserInDB(){

            let data = { 
                'User_Id' : this.User_Id,
                'Update'  : {
                    Email    : this.Email,
                    Password : this.Password,
                    Priority : parseInt(this.Priority)
                }
            }
            this.$eventHub.$emit("LOADING",'true')
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/update-user`,data)
                .then(response => {

                    this.$eventHub.$emit("LOADING",'false')
                    if(response && response['data'] && response['data']['response']){

                        let index = this.items.findIndex(x => x['_id'] == response['data']['response']['_id'])
                        index > -1 ? this.items.splice(index,1,response['data']['response']) : {}
                        this.Clear()
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "User Updated Successfully","Color":"green","Close": false });

                    }else{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "User Not Deleted","Color":"red","Close": false });
                    }
                })
                .catch(error => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Deleting User","Color":"red","Close": false });
                })

        },
        DeleteUser(data){
            
            this.$eventHub.$emit("LOADING",'true')
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/delete-user`,data)
                .then(response => {

                    this.$eventHub.$emit("LOADING",'false')
                    if(response && response['data'] && response['data']['response']){

                        let index = this.items.findIndex(x => x['_id'] == response['data']['response']['_id'])
                        index > -1 ? this.items.splice(index,1) : {}

                    }else{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "User Not Deleted","Color":"red","Close": false });
                    }
                })
                .catch(error => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Deleting User","Color":"red","Close": false });
                })

        }
    },
    mounted(){

        this.$eventHub.$emit("LOADING",'false')
        this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/find-user`,{})
                .then(response => {

                    this.$eventHub.$emit("LOADING",'false')
                    if(response && response['data'] && response['data']['response']){

                        this.items = response['data']['response']

                    }else{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "User Not Created","Color":"red","Close": false });
                    }
                })
                .catch(error => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Creating User","Color":"red","Close": false });
                })
    }
}
</script>
<style>
.ma-2{
    margin:20px;
}
</style>