<template>
    <v-flex>
        <v-flex style="display:flex;" ma-3>
            <v-btn color='primary' @click="GetInformation">refresh logs</v-btn>
            <v-btn color='primary' @click="ResetInformation">reset logs</v-btn>
            <v-flex style="margin:12px;font-weight: bold;">Total Connected Systems : {{ Clients.length }}</v-flex>
        </v-flex>
        <v-flex style="display:flex;">
            <v-flex xs6 ma-4>
                <v-flex style="margin:12px;font-weight: bold;">Client Logs :</v-flex>
                <v-flex style="display:flex;font-size: 16px;text-transform: capitalize;" v-for="(log,index) in GetLogsFromStore" :key="index">
                    <section v-if="log">
                        <span> {{ index }} . </span>
                        <span> {{ log }} </span>
                    </section>
                </v-flex>
            </v-flex>
            <v-flex xs6 ma-4>
                <v-flex style="margin:12px;font-weight: bold;">Connected Systems</v-flex>
                <v-flex v-for="(client,index) in Clients" :key="index"> {{ index + 1}} . {{ client.mac }} / {{ client.email }}</v-flex>
            </v-flex>
        </v-flex>
    </v-flex>
</template>
<script>

let interval = null;

export default {
    data(){
        return {
            Clients : []
        }
    },
    computed:{
        GetLogsFromStore(){

            return this.$store.getters.getLogs
        }
    },
    watch:{
        GetLogsFromStore(){

            this.$eventHub.$emit("LOADING",'false');

        }
    },
    methods:{
        GetInformation(){

            this.$Socket.GetLogs();
            console.log("Got Logs")
        },
        ResetInformation(){

        }
    },
    destroyed() {
        clearInterval(interval);
    },
    mounted(){

        this.GetInformation()
        let _this = this
        interval = setInterval(function() {
            _this.GetInformation()
        },6000)
    }
}
</script>
<style>

</style>