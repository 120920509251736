<template>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
          <v-flex lg3 sm6 xs12>
            <v-flex class="dasInfo-box">
                <span class="dasInfo-box-icon bg-info">
                  <v-icon large color="white">laptop</v-icon>
                </span>
                <div class="dasInfo-box-content">
                  <span class="dasInfo-box-text">Total Systems</span>
                  <span class="dasInfo-box-number">{{ total_systems.length }}</span>
                </div>
            </v-flex>
          </v-flex>
          <v-flex lg3 sm6 xs12>
           <v-flex class="dasInfo-box">
              <span class="dasInfo-box-icon bg-info">
                <v-icon large color="white">online_prediction</v-icon>
              </span>
              <div class="dasInfo-box-content">
                <span class="dasInfo-box-text">Online Systems</span>
                 <span class="dasInfo-box-number">{{ Systems }}</span>
              </div>
          </v-flex>
          </v-flex>
          <v-flex lg3 sm6 xs12>
           <v-flex class="dasInfo-box">
              <span class="dasInfo-box-icon bg-info">
                <v-icon large color="white">book_online</v-icon>
              </span>
              <div class="dasInfo-box-content">
                <span class="dasInfo-box-text">Online Students</span>
                 <span class="dasInfo-box-number">{{ Students }}</span>
              </div>
          </v-flex>
          </v-flex>
          <!-- <v-flex lg3 sm6 xs12>
           <v-flex class="dasInfo-box">
              <span class="dasInfo-box-icon bg-info">
                <v-icon large color="white">people</v-icon>
              </span>
              <div class="dasInfo-box-content">
                <span class="dasInfo-box-text">Total Systems</span>
                 <span class="dasInfo-box-number">0</span>
              </div>
          </v-flex>
          </v-flex> -->
      </v-layout>
      <v-flex>
          <v-flex ma-2 class="headline">Exam Panel Settings</v-flex>
          <v-layout row wrap>
             <v-flex lg4>
                <v-card class="clsForVCard">
                    <v-card-title class="text-truncate" style="position:relative;">
                      Login Flow Settings
                      <span class="clsCardBtn" :style="{ background : `${ edit_flow ? 'green': 'royalblue' }`}">
                          <v-icon color='white' v-if="!edit_flow" @click="edit_flow = true">edit</v-icon> 
                          <v-icon color='white' v-if="edit_flow" @click="SaveFlowSettings">save</v-icon> 
                      </span>
                    </v-card-title>
                    <v-card-text style="text-align: center;" v-if="!edit_flow">
                      <v-flex pa-0 style="text-align: center;">Skip System Details :
                        <v-spacer></v-spacer> 
                        <span style="font-weight: bold;"> {{ skip_system ? 'Disabled' : 'Enabled' }} </span>
                      </v-flex>
                      <v-flex pa-0 style="text-align: center;">Skip Verification Details :
                        <v-spacer></v-spacer> 
                        <span style="font-weight: bold;"> {{ skip_verify ? 'Disabled' : 'Enabled' }}</span>
                      </v-flex>
                      <v-flex pa-0 style="text-align: center;">Skip Image Upload :
                        <v-spacer></v-spacer> 
                        <span style="font-weight: bold;"> {{ skip_upload ? 'Disabled' : 'Enabled' }}</span>
                      </v-flex>
                       <v-flex pa-0 style="text-align: center;" >Login Form : 
                          <v-spacer></v-spacer> 
                          <span v-for="(form,index) in login_form" :key="form.user_name + index" style="font-weight: bold;">{{ `Input ${index + 1} : ${ form.label } ` }} {{ index < login_form.length - 1 ? ' , ' : ''  }}</span>
                          <v-spacer></v-spacer> 
                      </v-flex>
                    </v-card-text>
                    <v-card-text style="text-align: center;" v-if="edit_flow">
                        <v-checkbox v-model="skip_system" :label="`Skip System Info is ${skip_system ? 'Enabled' : 'Disabled'}`" ></v-checkbox>
                        <v-checkbox v-model="skip_verify" :label="`Skip Verification is ${skip_verify ? 'Enabled' : 'Disabled'}`" ></v-checkbox>
                        <v-checkbox v-model="skip_upload" :label="`Image Upload ${skip_upload ? 'Enabled' : 'Disabled'}`" ></v-checkbox>
                        <v-btn color='primary' small @click="form_dialog = true">Edit Login Form</v-btn>
                    </v-card-text>
                </v-card>
              </v-flex>
              <v-flex lg4>
                <v-card class="clsForVCard">
                    <v-card-title class="text-truncate" style="position:relative;">
                      Login Time Settings
                      <span class="clsCardBtn" :style="{ background : `${ edit_time ? 'green': 'royalblue' }`}">
                          <v-icon color='white' v-if="!edit_time" @click="edit_time = true">edit</v-icon> 
                          <v-icon color='white' v-if="edit_time" @click="SaveTimeSettings">save</v-icon> 
                      </span>
                    </v-card-title>
                    <v-card-text style="text-align: center;" v-if="!edit_time">
                      <v-flex pa-2 style="text-align: center;">Before Start Time :
                        <v-spacer></v-spacer> 
                        <span style="font-weight: bold;"> {{ before_time }} </span> Minutes
                      </v-flex>
                      <v-flex pa-2 style="text-align: center;">After Start Time  :
                        <v-spacer></v-spacer> 
                        <span style="font-weight: bold;"> {{ after_time }}</span> Minutes
                      </v-flex>
                    </v-card-text>
                    <v-card-text style="text-align: center;" v-if="edit_time">
                       <v-text-field type='Number' v-model="before_time" label="Before Start Time" ></v-text-field>
                       <v-text-field type='Number' v-model="after_time" label="After Start Time"></v-text-field>
                    </v-card-text>
                </v-card>
              </v-flex>
              <v-flex lg4>
                 <v-card class="clsForVCard">
                    <v-card-title class="text-truncate" style="position:relative;">
                      Validation Settings
                      <span class="clsCardBtn" :style="{ background : `${ edit_validation ? 'green': 'royalblue' }`}">
                          <v-icon color='white' v-if="!edit_validation" @click="edit_validation = true" >edit</v-icon>
                          <v-icon color='white' v-if="edit_validation" @click="SaveValidationSettings" >save</v-icon> 
                      </span>
                    </v-card-title>
                    <v-card-text style="text-align: center;" v-if="!edit_validation">
                      <v-flex pa-2 style="text-align: center;">Time Validation :
                        <v-spacer></v-spacer> 
                        <span style="font-weight: bold;"> {{ time_validation ? 'Enabled' : 'Disabled'}} </span>
                      </v-flex>
                      <v-flex pa-2 style="text-align: center;">System Validation  :
                        <v-spacer></v-spacer> 
                        <span style="font-weight: bold;"> {{ system_validation ? 'Enabled' : 'Disabled' }} </span>
                      </v-flex>
                    </v-card-text>
                     <v-card-text style="text-align: center;" v-if="edit_validation">
                        <v-checkbox v-model="time_validation" :label="`Time Validation is ${time_validation ? 'Enabled' : 'Disabled'}`" ></v-checkbox>
                        <v-checkbox v-model="system_validation" :label="`System Validation is ${system_validation ? 'Enabled' : 'Disabled'}`" ></v-checkbox>
                    </v-card-text>
                </v-card>
              </v-flex>
              <v-flex lg4>
                 <v-card class="clsForVCard">
                    <v-card-title class="text-truncate" style="position:relative;">
                      Feature Settings
                      <span class="clsCardBtn" :style="{ background : `${ edit_feature ? 'green': 'royalblue' }`}">
                          <v-icon color='white' v-if="!edit_feature" @click="edit_feature = true" >edit</v-icon>
                          <v-icon color='white' v-if="edit_feature" @click="SaveFeatureSettings" >save</v-icon>
                      </span>
                    </v-card-title>
                    <v-card-text style="text-align: center;" v-if="!edit_feature">
                      <v-flex pa-2 style="text-align: center;">Shuffle Questions :
                        <v-spacer></v-spacer> 
                        <span style="font-weight: bold;"> {{ question_shuffle ? 'Enabled' : 'Disabled' }}  </span>
                      </v-flex>
                      <v-flex pa-2 style="text-align: center;">Login Limit :
                        <v-spacer></v-spacer> 
                        <span style="font-weight: bold;"> {{ login_limit }}  </span>
                      </v-flex>
                    </v-card-text>
                    <v-card-text style="text-align: center;" v-else>
                      <v-checkbox v-model="question_shuffle" :label="`Shuffle Question is ${question_shuffle ? 'Enabled' : 'Disabled'}`" ></v-checkbox>
                      <v-text-field type='Number' v-model="login_limit" label="Login Limits"></v-text-field>
                    </v-card-text>
                </v-card>
              </v-flex>
              <v-flex lg4>
                 <v-card class="clsForVCard">
                    <v-card-title class="text-truncate" style="position:relative;">
                      Exam Panel Control
                    </v-card-title>
                    <v-card-text style="text-align: center;">
                      <v-btn color='error' small @click="Relaunch">Relaunch</v-btn>
                      <v-btn color='error' small @click="Shutdown">Shutdown</v-btn>
                      <v-btn color='error' small @click="Reset">Reset</v-btn>
                    </v-card-text>
                </v-card>
              </v-flex>
              <v-flex lg4>
                 <v-card class="clsForVCard">
                    <v-card-title class="text-truncate" style="position:relative;">
                      Mock Test Control
                    </v-card-title>
                    <v-card-text style="text-align: center;">
                      <v-flex pa-2 style="text-align: center;">Mock Test Status 
                        <v-spacer></v-spacer> 
                        <span style="font-weight: bold;"> {{ Admin && Admin['mock_exam'] ? "Active" : "Inactive"}}   </span>
                      </v-flex>
                        <v-btn small color='error' @click="StopMock" v-if="Admin && Admin['mock_exam']">Stop Mock Test</v-btn>
                        <v-btn small color='primary' @click="StartMock" v-else>Start Mock Test</v-btn>
                        <v-tooltip color='success' bottom>
                            <template v-slot:activator="{ on, attrs }">
                               <v-btn  class="ma-2" fab small outlined color="primary" v-bind="attrs" v-on="on">
                                  <v-icon @click="GetMockInformation" v-bind="attrs" v-on="on">priority_high</v-icon>
                               </v-btn>
                            </template>
                            <span>Mock Analysis</span>  
                        </v-tooltip>
                    </v-card-text>
                </v-card>
              </v-flex>
              <!-- <v-flex lg4>
                 <v-card class="clsForVCard">
                    <v-card-title class="text-truncate" style="position:relative;">
                      Exam Data Backup
                    </v-card-title>
                    <v-card-text style="text-align: center;">
                      <v-btn color='primary'> Get Backup </v-btn>
                      <v-btn color='primary'> Send Backup Zip </v-btn>
                    </v-card-text>
                </v-card>
              </v-flex> -->
          </v-layout>
      </v-flex>
      <v-dialog v-model="form_dialog" max-width="600" persistent>
          <v-card>
                <v-card-title class="headline grey lighten-3" primary-title>
                    <v-flex>Login Form</v-flex>
                    <v-flex style="padding: 0px 20px;">
                        <v-icon @click="form_dialog = false" style="float:right;">close</v-icon>
                    </v-flex>
                </v-card-title>
                <v-card-text>
                    <v-select item-value='input' item-text='label' return-object v-model="selected_input_1" :items="form_inputs_1" label="Select First Input">
                        <template slot='selection' slot-scope='{ item }'>
                            {{ item.label }}
                        </template>
                        <template slot='item' slot-scope='{ item }'>
                            {{ item.label }} 
                        </template>
                    </v-select>
                    <v-select item-value='input' item-text='label' return-object v-model="selected_input_2" :items="form_inputs_2" label="Select Second Input">
                        <template slot='selection' slot-scope='{ item }'>
                            {{ item.label }}
                        </template>
                        <template slot='item' slot-scope='{ item }'>
                            {{ item.label }} 
                        </template>
                    </v-select>
                </v-card-text>
                <v-btn @click="SaveLoginForm" color='primary' small> Save Login Form</v-btn>
          </v-card>
      </v-dialog>
      <v-dialog v-model="mock_dialog" max-width="600" persistent>
         <v-card>
                <v-card-title class="headline grey lighten-3" primary-title>
                    <v-flex>Mock Information</v-flex>
                    <v-flex style="padding: 0px 20px;">
                        <v-icon @click="mock_dialog = false" style="float:right;">close</v-icon>
                    </v-flex>
                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="Headers" :items="mock_info" hide-actions disable-initial-sort height="inherit" item-key="_id">
                        <template v-slot:headers="props">
                            <tr>
                              <th v-for="header in props.headers" :key="header.text">
                                  {{ header.text }}
                              </th>
                            </tr>
                        </template>
                        <template v-slot:items="props">
                            <tr>
                              <td class="text-xs-center">{{ props.item.system && Object.keys(props.item.system).length > 0 ? props.item.system.system_no : props.item.id }}</td>
                              <td class="text-xs-center">
                                <span v-if="props.item.system && Object.keys(props.item.system).length > 0">
                                    <span v-for="(disconnect,index) in props.item.system.disconnections" :key="disconnect">
                                      {{ index + 1 }} . {{ new Date().toLocaleTimeString() }} , 
                                    </span>
                                </span>
                                <span v-else> 
                                    <span v-for="(disconnect, index) in props.item.disconnections" :key="disconnect">
                                       {{ index + 1 }} . {{ new Date(disconnect).toDateString() }} , {{ new Date().toLocaleTimeString() }}
                                    </span>
                                </span>
                              </td>
                              <td class="text-xs-center">{{ props.item.Response ? props.item.Response.length : 0}}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
          </v-card>
      </v-dialog>
    </v-container> 
</template>
<script>

import io from 'socket.io-client';

let interval = null;
export default {
      components: {
        
      },
      data(){
        return {

            edit_time         : false,
            edit_validation   : false,
            edit_feature      : false,
            edit_flow         : false,
            before_time       : 0,
            after_time        : 0,
            time_validation   : false,
            system_validation : false,
            question_shuffle  : false,
            login_limit       : 5,
            total_systems     : [],
            skip_system       : false,
            skip_verify       : false,
            skip_upload       : false,
            login_form        : [],
            form_dialog       : false,
            select_inputs     : [
              { "input" : "first_name" , type: "text" , "label" : "Roll No" } , 
              { "input" : "password" , type: "password" , "label" : "Password" },
              { "input" : "email" , type: "text" , "label" : "Registration Number" },
              { "input" : "dob" , type: "text" , "label" : "Date Of Birth" },
              { "input" : "id" , type: "text" , "label" : "User Id" }
            ],
            selected_input_1 : {},
            selected_input_2 : {},
            mock_exam        : false,
            mock_dialog      : false,
            mock_info        : [],
            Headers   : [
                { text: 'System No' , value : "system_no"},
                { text: "Disconnections", value : "disconnections"},
                { text: "Responses", value : "Response"},
            ],

        }
      },
      computed:{
        connection(){
            return this.$store.getters.GetConnectionStatus;
        },
        Center_Id(){
            return this.$store.getters.getCenter_id
        },
        Admin(){
          return this.$store.getters.GetAdminInfo
        },
        Systems(){
          return this.$store.getters.GetOnline.length
        },
        Students(){
          let students = this.$store.getters.GetOnline
          return students.length > 0 ? students.filter(x => !x.first_name.includes('temp')).length : 0
        },
        form_inputs_1(){
          let select = this.selected_input_2 && Object.keys(this.selected_input_2).length > 0 ? this.selected_input_2 : false
          return select ? this.select_inputs.filter(x => x.input != select.input) : this.select_inputs
        },
        form_inputs_2(){
          let select = this.selected_input_1 && Object.keys(this.selected_input_1).length > 0 ? this.selected_input_1 : false
          return select ? this.select_inputs.filter(x => x.input != select.input) : this.select_inputs
        }
      },
      watch:{
        connection(){
            if(this.connection){
              this.$eventHub.$emit("LOADING",'false')
            }     
        },
        Admin(){
          if(this.Admin){
            this.before_time       = this.Admin['before_exam_time']
            this.after_time        = this.Admin['after_exam_time']
            this.time_validation   = this.Admin['time_validation']
            this.system_validation = this.Admin['system_validation']
            this.question_shuffle  = this.Admin['question_shuffle']
            this.login_limit       = this.Admin['login_limit']
            this.mock_exam         = this.Admin['mock_exam']
          }
        }   
      },
      methods:{
        Relaunch(){

          this.$Socket.Relaunch()

        },
        Shutdown(){

          this.$Socket.Shutdown()
        },
        Reset(){

          this.$Socket.Reset()

        },
        SaveTimeSettings(){

            this.SaveToDatabase({ 'center_id' : this.Center_Id , "update" : { "before_exam_time" : this.before_time , "after_exam_time" : this.after_time }})
        
        },
        SaveValidationSettings(){

            this.SaveToDatabase({'center_id' : this.Center_Id , "update" : { "system_validation" : this.system_validation , "time_validation" : this.time_validation }})
        
        },
        SaveFeatureSettings(){

            this.SaveToDatabase({'center_id' : this.Center_Id , "update" : { "question_shuffle" : this.question_shuffle , "login_limit" : this.login_limit }})
       
       },
       SaveFlowSettings(){

        
         let data = { Skip_System : this.skip_system , Skip_Verify : this.skip_verify , Form : this.login_form , skip_upload : this.skip_upload }
         this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/set-init-config`,data)
                .then(response => {

                    if(response && response['data'] && response['data']['response']){
                    
                      this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Successfully Updated Settings","Color":"green","Close": false });
                      this.skip_system = response['data']['response']['Skip_System'],
                      this.skip_verify = response['data']['response']['Skip_Verify'],
                      this.skip_upload = response['data']['response']['skip_upload'],
                      this.login_form  = response['data']['response']['Login_Form']

                    }else{
                      
                      this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Failed To Update Settings","Color":"red","Close": false });
                    }
                    this.$eventHub.$emit("LOADING",'false')
                    this.edit_flow = false;
                })
                .catch(error => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Updating Settings","Color":"red","Close": false });
                })


       },
       SaveLoginForm(){

         let Form = [
            { "user_name" : this.selected_input_1['input'] , "label"  :  this.selected_input_1['label']  ,'type' : this.selected_input_1['type']  } , 
            { "password"  : this.selected_input_2['input'] , "label"  :  this.selected_input_2['label'] , 'type' : this.selected_input_2['type']  } 
         ]

         let data = { 'Form' : Form , Skip_System : this.skip_system , Skip_Verify : this.skip_verify , skip_upload : this.skip_upload }
         this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/set-init-config`,data)
                .then(response => {

                    if(response && response['data'] && response['data']['response']){
                    
                      this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Successfully Updated Settings","Color":"green","Close": false });
                      this.skip_system = response['data']['response']['Skip_System'],
                      this.skip_verify = response['data']['response']['Skip_Verify'],
                      this.skip_upload = response['data']['response']['skip_upload'],
                      this.login_form  = response['data']['response']['Login_Form']

                    }else{
                      
                      this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Failed To Update Settings","Color":"red","Close": false });
                    }
                    this.$eventHub.$emit("LOADING",'false')
                    this.edit_flow = false;
                })
                .catch(error => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Updating Settings","Color":"red","Close": false });
                })

       },
       SaveToDatabase(data){

          this.$eventHub.$emit("LOADING",'true')
          this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/set-settings`,data)
                .then(response => {

                    if(response && response['data'] && response['data']['response']){
                    
                      this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Successfully Updated Settings","Color":"green","Close": false });
                      this.$store.commit('setAdmin',response['data']['response'])

                    }else{
                      
                      this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Failed To Update Settings","Color":"red","Close": false });
                    }
                    this.$eventHub.$emit("LOADING",'false')
                    this.edit_time = false
                    this.edit_validation = false
                    this.edit_feature = false
                })
                .catch(error => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Updating Settings","Color":"red","Close": false });
                })

        },
        FindSystems(){

            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/systems`,{})
                  .then(response => {

                      if(response && response['data'] && response['data']['response']){
                          
                          let system = response['data']['system']
                          this.total_systems = response['data']['response'];
                          this.skip_system = system['Skip_System'],
                          this.skip_verify = system['Skip_Verify'],
                          this.login_form  = system['Login_Form']
                          this.$Socket.GetOnline();

                      }else{

                          this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Failed To Fetch Systems","Color":"red","Close": false });
                      }

                      if(this.connection){
                        this.$eventHub.$emit("LOADING",'false')
                      }
                  })
                  .catch(error => {

                      this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Fetching Systems","Color":"red","Close": false });
                  })

        },
        StartMock(){

          this.$eventHub.$emit("LOADING",'true');
          let data = this.Admin
          this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/sync-mock-exam`,data)
              .then(response =>{

                if(response && response['data'] && response['data']['response']){

                  this.$Socket.StartMock();

                }else{
                  
                   this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Mock Exam Not Synced","Color":"red","Close": false });
                }
                this.$eventHub.$emit("LOADING",'false');

              })
              .catch(error =>{

                this.$eventHub.$emit("LOADING",'true');
                this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Mock Exam Not Synced ${error}`,"Color":"red","Close": false });
              })
        },
        StopMock(){

          this.$Socket.StopMock();

        },
        GetMockInformation(){
             
             this.$eventHub.$emit("LOADING",'true');
             this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/fetch-mock-analysis`,{})
                  .then(response => {

                      if(response && response['data'] && response['data']['response'] && response['data']['response'].length > 0){

                        this.mock_dialog = true;
                        this.mock_info = response['data']['response']

                      }else if(response && response['data'] && response['data']['response'] && response['data']['response'].length < 0){

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Mock Information Not Available Run Mock Test","Color":"red","Close": false });
                      
                      }else{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Mock Information Not Available Run Mock Test","Color":"red","Close": false });
                      }
                      this.$eventHub.$emit("LOADING",'false');
                  })
                  .catch(error => {

                      this.$eventHub.$emit("LOADING",'false');
                      this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Fetching Mock Info","Color":"red","Close": false });
                  })

        }
      },
      mounted(){

          this.FindSystems()

      }
}
</script>
<style>
.dasInfo-box {
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    border-radius: .25rem;
    padding: .5rem;
    background: #fff;
    display: -ms-flexbox;
    display: flex;
}
.dasInfo-box-icon {
    border-radius: .25rem;
    display: block;
    width: 84px;
    padding: 5px;
    height: 74px;
    display: grid;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #fff;
    margin: auto;
}
.bg-info {
    background-color: royalblue;
}
.dasInfo-box-content {
    padding: 5px 10px;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
}
.dasInfo-box-text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
}
.dasInfo-box-number {
    font-size: 16px;
    font-weight: 700;
    color: #5f5f5f;
    font-size: 20px;
}
.settings-box {
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    border-radius: .25rem;
    background: #fff;
    display: -ms-flexbox;
    padding: 10px !important;
}
.clsForVCard{
    height: 205px !important;
}
.clsForVCard .v-card__title{
    box-shadow: 0px 2px 4px 0px #4a4a4a40;
  font-weight: bold !important;
  width: 100%;
  text-align: center !important;
}
.clsCardBtn{
  position: absolute;
    right: 15px;
    padding: 4px;
    box-shadow: 0px 0px 4px 1px #00000030;
    border-radius: 14%;
    background: royalblue;
    cursor: pointer;
}
.clsForVCard .v-input--checkbox {
    margin: 0px !important;
}
.clsForVCard .v-input__slot{
  margin: 0px !important;
}
</style>