const state = {

    Loading               : false,
    connection_status     : false,
    Production_URL        : 'test-series-server.examin.online',
    Dev_URL               : "http://localhost:3003",
    Local_URL             : '',
    Server_Time           : null
    
  }
  
  const mutations = {
      SetLoading(state,payload){
        state.Loading = payload
      },
      SetConnectionStatus(state, payload){
          state.connection_status = payload
      },
      SetLocalURL(state, payload){
         state.Local_URL = payload
      },
      SetServerTime(state, payload){
         state.Server_Time = payload
      }
  }
  
  const actions = {
      
  }
  
  const getters = {
      GetProductionUrl(){
        
        if(process.env.NODE_ENV == 'development'){

          return state.Dev_URL;
        }
        else if(process.env.VUE_APP_BUILD_TYPE == 'Web') {

          return state.Production_URL;

        }else{

          return state.Local_URL ? state.Local_URL : state.Dev_URL ;

        }
      },
      GetLoading(state){
        return state.Loading 
      },
      GetServer(state){

          if(process.env.NODE_ENV == 'development'){

            return state.Dev_URL + '/admin';
          }
          else if(process.env.VUE_APP_BUILD_TYPE == 'Web') {

            return state.Production_URL  + '/admin';

          }else{

            return state.Local_URL ? state.Local_URL  + '/admin' : state.Dev_URL  + '/admin';

          }
      },
      // GetBackUpServer(){

      //     if(process.env.NODE_ENV == 'development'){
    
      //       return 'http://localhost:3001'
    
      //     }else{
    
      //         return 'https://api.exambackupserver.com'
              
      //     }
      //   },
        GetConnectionStatus(state){
            return state.connection_status
        }
  }
  
  export default {
    state,
    mutations,
    actions,
    getters
  }
  