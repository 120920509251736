<template>
    <div class="main-container">
        <v-layout row>
                <v-flex xs5 pa-4 style="margin:auto;margin-top:10%;">
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-toolbar-title>Connect To Server</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-card-text style="padding-bottom: 0px;">
                                <v-form>
                                    <v-text-field label="Server IP or Domain" v-model="server_ip"></v-text-field>
                                    <v-text-field label="Server Port" v-model="server_port"></v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="justify-center">
                                <v-btn style="padding: 0px 30px;" color="primary" @click="SaveAndConnect">Connect To Server</v-btn>
                            </v-card-actions>
                        </v-card>
                </v-flex>
        </v-layout>
        <v-spacer></v-spacer>
    </div>
</template>
<script>

import io from 'socket.io-client';
import os from 'os'

export default {
    data(){
        return {
            server_ip   : "",
            server_port : "",
        }
    },
    computed:{
        connection(){
            return this.$store.getters.GetConnectionStatus;
        }
    },
    watch:{
        connection(){
            if(this.connection){
                this.$router.push('/login')
                this.$eventHub.$emit("LOADING",'false')
            }            
        }   
    },
    methods:{       
        SaveAndConnect(){

            if( this.server_ip && this.server_port ){

                let URL = `http://${this.server_ip}:${this.server_port}/admin`;
                localStorage.setItem("server_ip",this.server_ip);
                localStorage.setItem("server_port",this.server_port);
                this.ConnectSocket(URL);

            }

        },
        ConnectSocket(URL){

            this.$store.commit('SetLocalURL',`http://${this.server_ip}:${this.server_port}`);
            this.$eventHub.$emit("LOADING",'true')
            this.$Socket.close();
            let socket = io(URL)
            this.$Socket.init(socket);
        },
        validatesystem(){

            this.server_ip = localStorage.getItem('server_ip');
            this.server_port = localStorage.getItem("server_port");
            if(this.server_ip && this.server_port){

                let URL = `http://${this.server_ip}:${this.server_port}/admin`;
                this.ConnectSocket(URL);

            }else{

                this.$eventHub.$emit("LOADING",'false');

            }

        }
    },
    mounted(){
        
        // this.$eventHub.$emit("LOADING",'false')
        this.validatesystem() 
    }    
}
</script>
<style scoped>

</style>