
const state = {
    systems: [],
    assignmentCompleted: null
}

const mutations = {
    setSystem(state, payload){

        let index = state.systems.findIndex(system => {
            return system.mac === payload.mac
        })

        payload.connected = true

        if(index === -1) {
            state.systems.push(payload)
        }
        else {
            state.systems.splice(index, 1, payload)
        }

    },
    setDisconnectedStatus(state, payload){

        let index = state.systems.findIndex(system => {
            return system.mac === payload.mac
        })

        if(index > -1) {

            let system = state.systems[index]

            system.connected = payload.status

            state.systems.splice(index, 1, system)

        }

    },
    setStudent(state, payload){

        console.log(payload)


        let index = state.systems.findIndex(system => {
            return system.mac === payload.mac
        })


        if(index > -1) {

            let system = state.systems[index]

            system.connected = payload.status

            system.student_id = payload.email

            state.systems.splice(index, 1, system)
        }

        console.log(state.systems)

    },
    setSystems(state, payload) {

        state.systems = payload

    },
    setAssignmentCompleted(state, payload){
        state.assignmentCompleted = payload
    }
}

const actions = {

}


const getters = {
    getSystems(state){
        return state.systems
    },
    getTotalSystems(state){
        return state.systems.length
    },
    getAssignmentCompleted(state){
        return state.assignmentCompleted
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
