const state = {
  ip_validation: false,
  login_l: 30,
  login_u: 30,
  time_validation:false,
  question_shuffle:false,
  calculator_enable:false,
  simple_calculator:false,
  select_option:false,
  virtual_keyboard:false,
  theme:0,
  priority_constant:100

}

const mutations = {
  setIpValidation(state, payload){
    state.ip_validation = payload
  },
  setLoginL(state, payload){

    if(!payload) {
      payload = 0
    }

    state.login_l = payload
  },
  setLoginU(state, payload){

      if(!payload) {
          payload = 0
      }
      state.login_u = payload
  },
  setTimeValidation(state, payload){
    state.time_validation = payload
  },
  setShuffleValidation(state, payload){
    state.question_shuffle = payload
  },
  setCalculator(state, payload){
    state.calculator_enable = payload
  },
  setCalculatorType(state, payload){
    state.simple_calculator = payload
  },
  setSelectExamOption(state, payload){
    state.select_option = payload
  },
  setVirtualKeyboard(state, payload){
    state.virtual_keyboard = payload
  },
  setTheme(state, payload){
    state.theme = payload
  },
  setPriorityConstant(state,payload){
    state.priority_constant = payload
  }
}

const actions = {
}

const getters = {
  getIpValidation(state){
    return state.ip_validation
  },
  getLoginL(state){
    return state.login_l
  },
  getLoginU(state){
    return state.login_u
  },
  getTimeValidation(state){
    return state.time_validation
  },
  getShuffleValidation(state){
    return state.question_shuffle
  },
  getCalculator(state){
    return state.calculator_enable
  },
  getCalculatorType(state){
     return state.simple_calculator
  },
  getSelectExamOption(state){
    return state.select_option
  },
  getVirtualKeyboard(state){
    return state.virtual_keyboard
  },
  getTheme(state,){
    return state.theme
  },
  getPriorityConstant(state){
    return state.priority_constant
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
