<template>
    <v-layout row justify-center>
            <v-flex>
                    <v-toolbar dark color="primary" >
                        <v-toolbar-title>Exam Details</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
            
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-flex ma-4>
                        <v-flex style="display:flex;">
                            <v-flex xs2 ma-2>
                                <v-text-field class="clsForZero"  label='Enter Exam Id' v-model="exam_id"></v-text-field>
                            </v-flex>
                            <v-flex xs2 ma-2>
                                <v-text-field class="clsForZero" type='password' label='Enter Password' v-model="password"></v-text-field>
                            </v-flex>
                            <v-btn color='primary' @click="SearchExamDetails">Search</v-btn>
                        </v-flex>
                        <v-flex v-if="exam_name">
                            <v-flex style="display:flex;text-align: center;font-weight: bold;text-transform: capitalize;">
                                <v-flex xs3>Exam : {{ exam_name }}</v-flex>
                                <v-flex xs2>Exam Duration :  {{ duration }} </v-flex>
                                <v-flex xs2>Total Questions : {{ total_questions }}</v-flex>
                                <v-flex xs3>
                                    <v-select class="clsForZero" v-model="Selected_Language" :items="languages" label="Select Language"></v-select>
                                </v-flex>
                                <v-flex xs2> <v-btn color='primary' @click="Instructiondialog = true">Instructions</v-btn></v-flex>
                            </v-flex>
                            <v-flex style="display:flex;">
                                <v-btn v-for="subject in subjects" @click="ChangeSubject(subject,0)" :key="subject.id" :color='Active_Subject.id == subject.id ? "primary" : ""'>{{ subject.name }} ( {{ subject_wise.find(x => x.id == subject.id)['question_count'] }})</v-btn>
                            </v-flex>
                            <v-flex>
                                <v-flex ma-4 style="height: calc( 100vh - 380px);overflow: auto;">
                                    <v-flex style="font-weight: bold;font-size: 16px;">Question No : {{ Active_Question.question_id }}</v-flex>
                                    <v-flex v-html="Question" style="padding:15px 4%;"></v-flex>
                                    <v-flex pr-5 pl-5 v-if="Active_Question.question_type === 'MCQ' || Active_Question.question_type === 'PAR'">
                                        <v-flex v-for="option in QuestionDetails.options" :key="option.value" >
                                            <v-flex v-if="option.text && option.text != null && option.text.trim() != ''">{{ option.value }} .{{ option.text }}</v-flex>
                                        </v-flex>
                                    </v-flex>
                                    <v-flex pr-5 pl-5 v-if="Active_Question.question_type === 'SC'">
                                        <v-flex v-for="option in QuestionDetails.options" :key="option.value">
                                            <v-flex style="display:flex;" v-if="option.text && option.text != null && option.text.trim() != ''">
                                            <span>{{ option.value }} .</span><span v-html="convertText(option.text)"></span>
                                            </v-flex>
                                        </v-flex>
                                    </v-flex>
                                </v-flex>
                                <v-flex>
                                    <v-btn color='primary' @click="PreviousQuestion">Previous</v-btn>
                                    <v-btn color='primary' @click="NextQuestion">Next</v-btn>
                                </v-flex>
                            </v-flex>
                        </v-flex>
                    </v-flex>
            </v-flex>
        <v-dialog v-model="Instructiondialog" width="700">

            <v-toolbar dark color="primary" >
                <v-toolbar-title>Instructions</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark icon @click="Instructiondialog = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card>
                <v-flex pa-5 v-html="instruction"></v-flex>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>
export default {
    data(){
        return {
            password    : "",
            exam_id     : "",
            exam_name   : "",
            subjects    : [],
            languages   : [],
            instruction : "",
            duration     : "",
            total_marks  : "",
            subject_wise : "",
            total_questions : "",
            Active_Subject  : {},
            Active_Question : {},
            Active_Subject_Questions : {},
            Selected_Language : "",
            Current_Question  : 0,
            Instructiondialog : false
        }
    },
    computed:{
        QuestionDetails(){
            return this.Active_Question && Object.keys(this.Active_Question).length > 0 ? this.Active_Question['locale'][this.Selected_Language] : "";
        },
        Question(){
            return this.Active_Question && Object.keys(this.Active_Question).length > 0 ? this.convertText(this.Active_Question['locale'][this.Selected_Language]['question_text']) : ""
        }
    },
    methods:{
        ChangeSubject(subject,question_count){

            this.Active_Subject  = subject;
            this.Active_Subject_Questions = this.subject_wise.find(x => x['id'] == subject['id'])
            this.Active_Question = this.Active_Subject_Questions['questions'][question_count];

        },
        Clear(){
            this.exam_name   = ""
            this.subjects    = []
            this.languages   = []
            this.instruction = ""
            this.duration     = ""
            this.total_marks  = ""
            this.subject_wise = ""
            this.total_questions = ""
            this.Active_Subject  = {}
            this.Active_Question = {}
            this.Active_Subject_Questions = {}
            this.Selected_Language = ""
            this.Current_Question  = 0
            this.Instructiondialog = false
            this.password = ""
        },
        SearchExamDetails(){
            
            this.$eventHub.$emit("LOADING",'true')
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/get-exam`, { 'exam_id' : this.exam_id ,'password' : this.password })
                .then(response =>{

                    this.$eventHub.$emit("LOADING",'false')
                        this.Clear();
                    if(response && Object.keys(response).length > 0 && response['data']['response']){
                        
                        this.SaveExamInfo(response['data']['response'])

                    }else{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Getting Exam Details","Color":"red","Close": false });
                    }
                })
                .catch(error =>{

                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Getting Exam Details","Color":"red","Close": false });
                })
        },
        SaveExamInfo(Exam){

            this.exam_id = Exam.exam_id;
            this.exam_name = Exam.exam_name;
            this.subjects = Exam.subjects;
            this.languages = Exam.languages;
            this.instruction = Exam.instruction;
            this.duration = Exam.duration;
            this.total_marks = Exam.total_marks;
            this.subject_wise = Exam.data;
            this.total_questions = Exam.total_questions;
            this.Selected_Language = Exam.languages[0];

            this.Active_Subject  = Exam.subjects[0];
            this.Active_Subject_Questions = Exam.data.find(x => x['id'] == Exam.subjects[0]['id'])
            this.Active_Question = this.Active_Subject_Questions['questions'][0]            
        },
        PreviousQuestion(){

            if(this.Current_Question > 0 ){

                this.Current_Question -= 1;
                this.Active_Question = this.Active_Subject_Questions['questions'][this.Current_Question];

            }else{

                let index  = this.subjects.findIndex(x => x['id'] == this.Active_Subject['id']);
                let Active = index > 0 ? this.subjects[index - 1] : this.subjects[this.subjects.length - 1]
                let question_count = parseInt(this.subject_wise.find(x => x['id'] == Active['id'])['question_count']) - 1
                this.Current_Question = question_count
                this.ChangeSubject(Active,question_count)
            }
        },
        NextQuestion(){

            if(this.Current_Question < parseInt(this.Active_Subject_Questions['question_count']) - 1){

                this.Current_Question += 1;
                this.Active_Question =this.Active_Subject_Questions['questions'][this.Current_Question];

            }else{

                let index  = this.subjects.findIndex(x => x['id'] == this.Active_Subject['id']) + 1;
                let Active = index > -1 && index <= this.subjects.length - 1 ? this.subjects[index] : index == this.subjects.length ? this.subjects[0] : this.subjects[0]
                this.Current_Question = 0
                this.ChangeSubject(Active,0)
            }
        }
    },
    mounted(){

        this.$eventHub.$emit("LOADING",'false')
    }
}
</script>
<style scoped>
.clsForZero{
    padding: 0px;
    margin: 0px;

}
</style> 