<template>
  <v-app class="primary split-bg">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <app-login v-if="VerifyLoginPath"></app-login>
            <app-user-login v-else></app-user-login>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import appLogin from '../../views/Login'
import appUserLogin from '../../views/LoginUser'
export default {
  components:{
      appLogin,
      appUserLogin
  },
  computed:{
    VerifyLoginPath(){
      return this.$router.currentRoute.name == 'Login'
    }
  },
  methods: {}
}
</script>
<style scoped lang="css">
.split-bg {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
