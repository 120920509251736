import { AuthLayout, DefaultLayout } from "@/components/layouts";
import NotFound     from '@/views/error/NotFound.vue';
import Deny         from '@/views/error/Deny.vue';
import Dashboard    from '@/views/Dashboard.vue';
import Exams        from '@/views/Exams.vue';
import Students     from '@/views/Students.vue';
import Invigilation from '@/views/Invigilation.vue'
import Service      from '@/views/Service.vue'
import Supervise    from '@/views/Supervise.vue'
import User         from '@/views/AdminUsers.vue';
import Attendance   from '@/views/StudentResponse.vue';
import Invigilate   from '@/views/FullInvigilation.vue';
import ExamDetails  from '@/views/ExamDetails.vue'
import ClientLogs   from '@/views/ClientLogs.vue'
import Server       from '@/views/Server.vue'
import System       from '@/views/Systems.vue'
import Home         from '@/views/Home.vue'


export const publicRoute = [
  {
    path: "/server",
    name: "Server",
    component: Server,
    meta: {
      title : "Server",
      child : 'admin',
      auth_required : false
    },
  },
  {
    path: "/login",
    name: "Login",
    component: AuthLayout,
    meta: {
      title : "Login",
      child : 'admin',
      auth_required : false
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: AuthLayout,
    meta: {
      title : "Admin",
      child : 'user',
      auth_required : false
    },
  },
  { 
    path: "/accessdeined",
    name: 'Access Deined',
    component: Deny,
    meta: {
      child : '',
      auth_required : false
    },
  },
  {
    path: "/supervise",
    name: 'Supervise',
    component: Supervise,
    meta: {
      child : 'user',
      auth_required : true
    },
  },
  {
    path: "/exam-details",
    name: 'Exam Details',
    component: ExamDetails,
    meta: {
      child : '',
      auth_required : false
    },
  },
  {
    path: "/invigilate/:center/:date/:session",
    name: "Invigilate",
    meta: {
      title: "Invigilate",
      group: "apps",
      icon: "article",
      child : 'admin',
      auth_required : true
    },
    component: Invigilate
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      title : "Home",
      child : 'admin',
      auth_required : false
    }
  },
  {
    path: "/",
    component: DefaultLayout,
    meta: {
      title: "Dashboard",
      group: "apps",
      icon: "",
      child : 'admin',
      auth_required : true
    },
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: {
          title: "Dashboard",
          group: "apps",
          icon: "dashboard",
          child : 'admin',
          auth_required : true
        },
        component: Dashboard
      },
      {
        path: "/exams",
        name: "Exams",
        meta: {
          title: "Exams",
          group: "apps",
          icon: "dashboard",
          child : 'admin',
          auth_required : true
        },
        component: Exams
      },
      {
        path: "/students",
        name: "Students",
        meta: {
          title: "Students",
          group: "apps",
          icon: "dashboard",
          child : 'admin',
          auth_required : true
        },
        component: Students
      },
      {
        path: "/system",
        name: "Systems",
        meta: {
          title: "Systems",
          group: "apps",
          icon: "dashboard",
          child : 'admin',
          auth_required : true
        },
        component: System
      },
      {
        path: "/invigilation",
        name: "Invigilation",
        meta: {
          title: "Invigilation",
          group: "apps",
          icon: "dashboard",
          child : 'admin',
          auth_required : true
        },
        component: Invigilation
      },
      {
        path: "/service",
        name: "Service",
        meta: {
          title: "Service",
          group: "apps",
          icon: "dashboard",
          child : 'admin',
          auth_required : true
        },
        component: Service
      },
      {
        path: "/users",
        name: "Users",
        meta: {
          title: "Users",
          group: "apps",
          icon: "dashboard",
          child : 'admin',
          auth_required : true
        },
        component: User
      },
      {
        path: "/attendance",
        name: "Attendance",
        meta: {
          title: "Attendance",
          group: "apps",
          icon: "article",
          child : 'admin',
          auth_required : true
        },
        component: Attendance
      },
      {
        path: "/client-logs",
        name: "Client Logs",
        meta: {
          title: "Client Logs",
          group: "apps",
          icon: "article",
          child : 'admin',
          auth_required : true
        },
        component: ClientLogs
      },
    ]
  },
  {
    path: "*",
    component: NotFound,
    meta: {
      child : '',
      auth_required : false
    }
  },
]