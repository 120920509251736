import Vue from "vue"
import Vuetify from "vuetify/lib"
import { Ripple } from 'vuetify/lib/directives'
import "../theme/default.styl"
Vue.use(Vuetify, {
  theme: {
    primary: "#4169E1",
    secondary: "#424242",
    accent: "#82B1FF",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107"
  },
  directives: {
    Ripple
  },
  customProperties: true
})
