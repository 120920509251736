<template>
    <v-layout row wrap ma-3>
        <v-flex style="margin: 0px 10px;display:flex;">
            <v-menu ref="menu" lazy :close-on-content-click="false" v-model="menu"
                    transition="scale-transition" offset-y full-width :nudge-right="40"
                    min-width="290px" :return-value.sync="date">
                <v-text-field slot="activator" label="Pick Exam Date"
                        v-model="date" prepend-icon="event" readonly></v-text-field>
                <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                    <v-btn flat color="primary"
                        @click="$refs.menu.save(date); GetStudentsResetPagination()">OK</v-btn>
                </v-date-picker>
            </v-menu>
            <v-btn fab dark small :disabled='!date' color='primary' @click="GetStudents(true)"><v-icon>refresh</v-icon></v-btn>
            <v-flex xs3>
                <v-select v-model="Selected_Session" :items="Sessions" label="Select Session" @change="SearchStudent"></v-select>
            </v-flex>
            <v-flex xs3>
                <v-text-field label="Search Students" v-model="Search" prepend-icon="user"></v-text-field>
            </v-flex>
            <v-btn color='primary' @click="SearchStudent"> Search </v-btn>
            <v-btn color='primary' @click="AddStudent">Add Student</v-btn>
        </v-flex>

         <v-data-table hide-actions item-key="first_name" style="width: 100%;margin: 10px;border: 1px solid #d3d3d34a;" :headers="headers" :items="items" :search="search">
            <template slot="items" slot-scope="props">
                <td class="text-xs-left">{{ ( (pages - 1) * 100 ) + (props.index + 1) }}</td>
                <td class="text-xs-left">{{ props.item.first_name }}</td>
                <td class="text-xs-left">{{ props.item.date ? props.item.date : new Date(props.item.datetime).toLocaleDateString() }}</td>
                <td class="text-xs-left">{{ props.item.time ? props.item.time : new Date(props.item.datetime).toLocaleTimeString() }}</td>
                <td class="text-xs-left">{{ props.item.submit_status ? "Submited" : "Not Submited" }}</td>
                <td class="text-xs-left">{{ props.item.login_time ? `${ new Date(props.item.login_time).toLocaleDateString() } , ${ new Date(props.item.login_time).toLocaleTimeString() }` : '-' }}</td>
                <td class="text-xs-left">{{ props.item.submit_time ? `${ new Date(props.item.submit_time).toLocaleDateString() } , ${ new Date(props.item.submit_time).toLocaleTimeString() }` : '-' }}</td>
                <td class="text-xs-left">{{ props.item.last_disconnected ? `${ new Date(props.item.last_disconnected).toLocaleDateString() } , ${ new Date(props.item.last_disconnected).toLocaleTimeString() }` : '-' }}</td>
                <td class="text-xs-left">
                    <v-icon style="padding: 5px;" class="cursor" medium color="primary" @click="ShowDetails(props.item)" title="More Details">more</v-icon>
                    <v-icon style="padding: 5px;" class="cursor" medium color="primary" @click="ResetStudent(props.item)" title="Reset">refresh</v-icon>
                    <v-icon style="padding: 5px;" class="cursor" medium color="error" @click="DeleteStudent(props.item)" title="More Details">delete</v-icon>
                </td>
            </template>
        </v-data-table>
        <v-pagination v-if="records > 0 && !search_option" v-model="pages" :length="Math.ceil(records/100)" :total-visible="10" @input="GetStudents(false)"></v-pagination>
        <v-pagination v-if="records > 0 && search_option" v-model="pages" :length="Math.ceil(records/100)" :total-visible="10" @input="SearchStudent(false)"></v-pagination>
        
        <v-dialog v-model="dialog" persistent max-width="450">
            <v-card>
                <v-card-title class="headline grey lighten-3" primary-title>
                    <v-flex>Student Details</v-flex>
                    <v-flex style="padding: 0px 20px;">
                        <v-icon @click="CloseDialog" style="float:right;">close</v-icon>
                    </v-flex>
                </v-card-title>
                <v-card-text>
                    <v-flex v-if="student && Object.keys(student).length > 0">
                        <v-flex style="display: flex;padding: 3px;">
                            <v-flex> <b> ID   : </b> {{ student.id }}</v-flex>
                            <v-flex> <b> Name : </b> {{ student.last_name }}</v-flex>
                        </v-flex>
                        <v-flex style="display: flex;padding: 3px;">
                            <v-flex> <b> Email : </b> {{ student.email }}</v-flex>
                            <v-flex> <b> Password : </b> {{ student.password }}</v-flex>
                        </v-flex>
                        <v-flex style="display: flex;padding: 3px;">
                            <v-flex> <b> Roll No : </b> {{ student.first_name }}</v-flex>
                            <v-flex> <b> DOB : </b> {{ new Date(student.dob).toISOString().split('T')[0] }}</v-flex>
                        </v-flex>
                        <v-flex style="display: flex;padding: 3px;">
                            <v-flex> <b> Class : </b> {{ student.class }}</v-flex>
                            <v-flex> <b> Exam Id : </b> {{ student.exam_id }}</v-flex>
                            <v-flex> <b> Exam Time : </b> {{ student.time }}</v-flex>
                        </v-flex>
                        <v-flex style="display: flex;padding: 3px;">
                            <v-flex> <b> URL : </b> exam-{{ student.identifier}}</v-flex>
                        </v-flex>
                    </v-flex>
                </v-card-text>
            </v-card>
        </v-dialog>
        <app-student-dialog :dialog='studentdialog' @close="CloseDialog"></app-student-dialog>
    </v-layout>
</template>
<script>
import appStudentDialog from './dialogs/AddStudent'
export default {
    components:{
        appStudentDialog
    },
    data(){
        return {

            refresh : false,
            date    : new Date().toISOString().split('T')[0],
            search  : '',
            menu    : false,
            inprogress : [],
            headers    : [
         
                { text: 'S.No', value: false },
                { text: 'Roll No', value: 'first_name' },
                { text: 'Date', value: 'date' },
                { text: 'Time', value: 'time' },
                { text: 'Submitted Status', value: 'submit_status' },
                { text: 'Login Time', value: 'login_time' },
                { text: 'Submit Time', value: 'submit_time' },
                { text: 'Last Disconnected', value: 'last_disconnected' },
                { text: 'Details', value: false }
            ],
            items    : [],
            pages    : 1, 
            records  : 0,
            temp_date  : "",
            dialog     : false,
            student    : {},
            studentdialog : false,
            Search        : "",
            time          : "",
            Sessions      : [ "All Sessions" ],
            Selected_Session : "All Sessions",
            search_option    : false

        }

    },
    computed:{
        Center_Id(){
            return this.$store.getters.getCenter_id
        }
    },
    methods:{
        ResetStudent(student){

            this.$eventHub.$emit("LOADING",'true')
            this.$http.post(`${this.$store.getters.GetProductionUrl}/student/reset-student`,student)
                    .then(response =>{

                        if(response && response['data'] && response['data']['response']){

                            let data = response['data']['response']
                            let Index = this.items.findIndex(x => x.id == data.id)
                            Index > -1 ? this.items[Index]['login_attempts'] = 0 : {}
                            Index > -1 ? this.items[Index]['screen_lock'] = false : {}
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Reset Successful","Color":"green","Close": false });


                        }else{

                            this.items = []
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Failed To Reset","Color":"red","Close": false });
                        }
                        this.$eventHub.$emit("LOADING",'false')

                    })
                    .catch(error =>{
                        this.$eventHub.$emit("LOADING",'false')
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Resetting","Color":"red","Close": false });
                    })

        },
        SearchStudent(){

            this.search_option = true;
            let data = {"search" : this.Search ,'date' : this.date }
            data['pagination'] =  { 'skip' : this.pages * 100 - 100  , 'limit' : 100 }
            this.Selected_Session != "All Sessions" ? data['time'] = this.Selected_Session : {}
            this.items = []
            this.$eventHub.$emit("LOADING",'true')
            this.$http.post(`${this.$store.getters.GetProductionUrl}/student/search-students`,data)
                    .then(response =>{

                        if(response && response['data'] && response['data']['response'] && response['data']['response'].length > 0){
                            
                            this.items = response['data']['response']
                            this.records = response['data']['records']

                        }else{

                            this.items = []
                            this.records = 0
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "No Students","Color":"red","Close": false });
                        }
                        this.$eventHub.$emit("LOADING",'false')

                    })
                    .catch(error =>{

                        this.search_option = false
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Searching","Color":"red","Close": false });
                    })
        },
        DeleteStudent(student){

            this.$eventHub.$emit("LOADING",'true')
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/delete-student`,student)
                .then(response => {

                    if( response && response['data'] && response['data']['response']){

                        let data = response['data']['response']
                        let Index = this.items.findIndex(x => x.id == data.id)
                        Index > -1 ? this.items.splice(Index,1) : {}
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Successfully Deleted","Color":"green","Close": false });

                    }else{
                        
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Deleting","Color":"red","Close": false });
                    }
                        this.$eventHub.$emit("LOADING",'false')

                })
                .catch(error => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Deleting","Color":"red","Close": false });
                })

        },
        CloseDialog(){
            this.dialog  = false
            this.studentdialog = false
            this.student = {}
        },
        ShowDetails(student){
            this.dialog  = true,
            this.student = student
        },
        GetStudentsResetPagination(){
            this.pages    = 1
            this.GetStudents(false)
        },
        GetStudents(refresh){
            
            this.search_option = false
            this.Sessions      = [ "All Sessions" ]
            this.Selected_Session = "All Sessions"
            let data = { 'data' : {'center_id': this.Center_Id, refresh: refresh , date: this.date } }
            data['pagination'] =  { 'skip' : this.pages * 100 - 100  , 'limit' : 100 }
            
            this.$eventHub.$emit("LOADING",'true')
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/students`,data)
                .then(response => {

                    this.$eventHub.$emit("LOADING",'false')
                    if(response && response['data'] && response['data']['data'] && response['data']['data'].length > 0 && response['data']['sessions']){

                        this.items = response['data']['data']
                        this.records = response['data']['records']
                        this.Sessions.push(...response['data']['sessions'])

                    }else{

                        this.items = []
                        this.records = 0
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "No Exams On This Date","Color":"red","Close": false });
                    }
                })
                .catch(error => {

                    this.$eventHub.$emit("LOADING",'false')
                    this.items = []
                    this.records = 0
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Fetching Exam List","Color":"red","Close": false });
                })
        },
        AddStudent(){
            this.studentdialog = true;
        }

    },
    mounted(){

        this.$eventHub.$emit("LOADING",'false')
    }
}
</script>
<style scoped>

</style>