import _ from 'lodash'

const state = {
    loading : false,
    config: null,
    students: [],
    headers: [],
    date: null,
    online: [],
    submitted: [],
    current_session:null,
    current_session_submit:0,
    submitted_count: 0,
    notification:null,
    /**
     * For Web Changes
     */
    New_Students : []
}

const mutations = {
    setStudents(state, payload){
        state.students = _.orderBy(payload, ['first_name'],['asc']);
        for(let i = 0 ; i < state.students.length;i++){
            if(state.students[i]["submit_status"] === true){
                state.submitted_count++
            }
        }
    },
    setConfig(state, payload){
        state.config = payload
        let headers = [{ text: 'S.No', value: false }]
        headers.push({ text: 'Roll No', value: 'first_name' })
        headers.push({ text: 'Date', value: 'date' })
        headers.push({ text: 'Time', value: 'time' })
        headers.push({ text: 'System No', value: 'system' })
        headers.push({ text: 'Submitted Status', value: 'submitted' })
        headers.push({ text: 'Login Time', value: 'login' })
        headers.push({ text: 'Submit Time', value: 'submit' })
        headers.push({ text: 'Last Disconnected', value: 'last_disconnected' })
        headers.push({ text: 'Details', value: false })
        state.headers = headers
    },
    setStudentSyncDate(state, payload){
        state.date = payload
    },
    setStudentSystem(state, payload){

        let index = state.students.findIndex((student) => {
            return payload.student_id === student.id
        })


        if(index > -1) {

            let student = state.students[index]

            student.system_no = payload.system_no

            state.students.splice(index, 1, student)

        }

    },
    setStudentStatus(state, payload){

        let index = state.students.findIndex(student => {

            return payload.id === student.id

        })

        if(index > -1) {

            let student = state.students[index]
            student.connected  = payload.status
            if(payload.status){

                student.login_time = new Date(Date.now()).toLocaleString();
            }
            if(!payload.status && payload.last_disconnected){

                student.last_disconnected = new Date(payload.last_disconnected).toLocaleString();

            }
            student.system_no  = payload.system_no

            state.students.splice(index, 1, student)

        }
    },
    setOnlineStudents(state, payload){

        console.log(payload)

        let index = state.online.findIndex(student => {

            return payload.id === student.id;
        })    
        if(index === -1 && payload.status === true) {
            state.online.push(payload)
        }

        if(index > -1 && payload.status === false) {
            state.online.splice(index, 1)
        }

    },
    setSubmittedStudents(state, payload){

        state.submitted_count++
        state.submitted.push(payload);

        let index = state.students.findIndex((student) => {

            return payload.user_id === student.id

        })
        if(index > -1) {

            let student = state.students[index]
            student.submit_status = true;
            student.submit_time = new Date(Date.now()).toLocaleString();
            let time = student["time"].split(":");
            if(state.current_session == time[0]+":"+time[1]){
                state.current_session_submit++
            }
        }
    },
    clearSubmittedStudentsCount(state){
        state.submitted_count = 0
    },
    setNotificationFromStudent(state, payload){
        state.notification = payload
    },
    setStudentsLoading(state,payload){
        state.loading = payload
    },
    /*
    * For Web Changes
    */
   SetNewStudents(state,payload){
      state.New_Students = payload
   },
   SetNewStudentsStatus(state,payload){

        let data = Object.keys(payload.OnlineStudents).map(key => payload.OnlineStudents[key] )
        state.New_Students = payload.Students.map(x => {
            !x.screen_lock ? x.screen_lock = false : x.screen_lock = true
            x.Online = data.findIndex(y => y.first_name == x.first_name) != -1 ? true : false
            return x
        })
   },
   SetStudentStatus(state,payload){
       
        let index = state.New_Students.findIndex(x => x.id == payload.id)
        index > -1 ? state.New_Students[index]['Online'] = payload.status : {}
   },
   UpdateNewStudent(state,payload){
       
        let index = state.New_Students.findIndex(x => x.id == payload.id)
        state.New_Students.splice(index, 1, payload)
   },
   UpdateScreenShot(state,payload){
       let images_data = []
       images_data.push(payload['Image'])
       let index = state.New_Students.findIndex(x => x.id == payload.id)
       index > -1 ? images_data.push(...state.New_Students[index]['images']) : {}
       index > -1 ? state.New_Students[index]['images'] = images_data : {}
   }
}

const actions = {


}

const getters = {
    getStudents(state){
        return state.students
    },
    getConfig(state){
        return state.config
    },
    getHeaders(state){
        return state.headers
    },
    getStudentSyncDate(state){
        return state.date
    },
    getOnlineStudents(state){
        return state.online
    },
    getSubmittedStudents(state){
        return state.submitted
    },
    getSubmittedStudentsCount(state){
        return state.submitted_count
    },
    getCurrentSubmittedStudents(state){
        return state.current_session_submit
    },
    getNotificationFromStudent(state){
        return state.notification
    },
    getStudentsLoading(state){
        return state.loading
    },
     /*
    * For Web Changes
    */
   GetNewStudents(state){
       
     return state.New_Students
   }
}

export default {
    state,
    mutations,
    actions,
    getters
}
