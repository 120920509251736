<template>
    <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="500">
            <v-card>
                <v-card-title class="headline grey lighten-3" primary-title>
                    <v-flex>Generate Attendance</v-flex>
                    <v-flex style="padding: 0px 20px;">
                        <v-icon @click="Close" style="float:right;">close</v-icon>
                    </v-flex>
                </v-card-title>
                <v-card-text>
                    <v-flex style="display:flex;">
                        <v-text-field style="margin: 4px;" v-model="Exam_Id" label="Exam Id"></v-text-field>
                    </v-flex>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="save">Generate</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>

    export default {
        props: ['dialog'],
        data(){
          return {

                Exam_Id  : "",

          }
        },
        computed: {
            Center_Id(){
                return this.$store.getters.getCenter_id
            },
            GetCenter(){
                return this.$store.getters.getCenter
            }
        },
        methods: {
            Close(){
                this.$emit('close', 'cancel')
                this.Exam_Id = 0
            },
            save(){
                
                let _this = this;
                let data = { 'center_name' : `${this.GetCenter['center_name']}(${this.Center_Id})` , "center_id" : this.Center_Id , "exam_id" : parseInt(this.Exam_Id) , "type" : "Exam"}
                this.$eventHub.$emit("LOADING",'true')
                this.$http.post(`${this.$store.getters.GetProductionUrl}/student/attendance`,data,{ responseType: "blob" })
                    .then(response => {

                            if(response && response.data){

                                this.$eventHub.$emit("LOADING",'false')
                                var file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                                const objectUrl = window.URL.createObjectURL(file)
                                var anchor = document.createElement("a");
                                anchor.download = `${data['center_name']}_${_this.Exam_Id}.xlsx`;
                                anchor.href = objectUrl;
                                anchor.click();
                            
                            }else{

                                this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Downloading Atttendance List","Color":"red","Close": false });
                            }   
                            this.$eventHub.$emit("LOADING",'false')

                    })
                    .catch(error => {

                        this.$eventHub.$emit("LOADING",'false')
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Fetching","Color":"red","Close": false });
                    })

            }
        },
        mounted(){

        }
    }
</script>