<template>
    <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="800">
            <v-card>
                <v-card-title class="headline grey lighten-3" primary-title>
                    <v-flex>Add Student</v-flex>
                    <v-flex style="padding: 0px 20px;">
                        <v-icon @click="Close" style="float:right;">close</v-icon>
                    </v-flex>
                </v-card-title>
                <v-card-text>
                    <v-flex style="display:flex;">
                        <v-flex xs6>
                            <v-text-field style="margin: 4px;" v-model="first_name" label="Roll No"></v-text-field>
                        </v-flex>
                        <v-flex xs6>
                            <v-text-field style="margin: 4px;" v-model="last_name" label="Name"></v-text-field>
                        </v-flex>
                    </v-flex>
                    <v-flex style="display:flex;">
                        <v-flex xs6>
                            <v-text-field style="margin: 4px;" v-model="email" label="Registration No"></v-text-field>
                        </v-flex>
                        <v-flex xs6>
                            <v-text-field style="margin: 4px;" v-model="password" label="Password"></v-text-field>
                        </v-flex>
                    </v-flex>
                    <v-flex style="display:flex;">
                        <v-flex xs6>
                             <v-text-field style="margin: 4px;" v-model="exam_id" label="Exam Id"></v-text-field>
                        </v-flex>
                        <v-flex xs6>
                            <v-menu ref="menu" lazy :close-on-content-click="false" v-model="menu"
                                    transition="scale-transition" offset-y full-width :nudge-right="40"
                                    min-width="290px" :return-value.sync="exam_date">
                                <v-text-field slot="activator" label="Exam Date" v-model="exam_date" readonly></v-text-field>
                                <v-date-picker v-model="exam_date" no-title scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                                    <v-btn flat color="primary" @click="$refs.menu.save(exam_date);">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-flex>
                    </v-flex>
                    <v-flex style="display:flex;">
                        <v-flex xs6>
                            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                :return-value.sync="exam_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="exam_time" label="Exam Time" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-time-picker v-if="menu2" v-model="exam_time" full-width @click:minute="$refs.menu2.save(exam_time)"></v-time-picker>
                            </v-menu>
                        </v-flex>
                        <v-flex xs6>
                            <v-text-field style="margin: 4px;" v-model="classes" label="Class" type='number'></v-text-field>
                        </v-flex>
                    </v-flex>
                    <v-text-field style="margin: 4px;" v-model="remarks" label="Remarks"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="save">Add Student</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>

    export default {
        props: ['dialog'],
        data(){
          return {
                id          : 0,
                first_name  : "",
                last_name   : "",
                dob         : new Date(),
                email       : "",
                password    : "",
                classes     : 0,
                exam_time   : "",
                exam_date   : new Date().toISOString().split('T')[0],
                exam_id     : 0,
                updated     : 0,
                menu        : false,
                menu2       : false,
                remarks     : ""
          }
        },
        computed: {
            Center_Id(){
                return this.$store.getters.getCenter_id
            }
        },
        methods: {
            Close(){
                this.$emit('close', 'cancel')
                this.id          = 0,
                this.first_name  = "",
                this.last_name   = "",
                this.dob         = new Date(),
                this.email       = "",
                this.password    = "",
                this.classes     = 0,
                this.exam_time   = "",
                this.exam_date   = new Date().toISOString().split('T')[0],
                this.exam_id     = 0,
                this.updated     = 0,
                this.menu        = false,
                this.menu2       = false,
                this.remarks     = ""
            },
            save(){

                let data = {
                    id: new Date().valueOf(),
                    first_name: this.first_name,
                    last_name: this.last_name,
                    dob: this.dob,
                    email: this.email,
                    password: this.password,
                    class: this.classes,
                    center_id: this.Center_Id,
                    datetime: new Date(this.exam_date + ' ' + this.exam_time),
                    exam_id: this.exam_id,
                    updated: 0,
                    remarks : this.remarks,
                    original : false
                }
                this.$eventHub.$emit("LOADING",'true')
                this.$http.post(`${this.$store.getters.GetProductionUrl}/student/create_student`,data)
                    .then(response => {

                        this.$eventHub.$emit("LOADING",'false')
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Successfully Added Student","Color":"green","Close": false });

                    })
                    .catch(error => {

                        this.$eventHub.$emit("LOADING",'false')
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Fetching Exam List","Color":"red","Close": false });
                    })

            }
        },
        mounted(){

        }
    }
</script>