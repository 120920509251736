<template>
    <v-flex mr-3>
      <v-data-table hide-actions item-key="first_name" style="width: 100%;margin: 10px;border: 1px solid #d3d3d34a;" :headers="headers" :items="Systems">
            <template slot="items" slot-scope="props">
                <td class="text-xs-left" :style="{ 'background': props.item.online ? '#2d922db8' : '#ff5252' , color: 'white'}">{{ props.index + 1 }}</td>
                <td class="text-xs-left" :style="{ 'background': props.item.online ? '#2d922db8' : '#ff5252' , color: 'white'}">{{ props.item.system_no }}</td>
                <td class="text-xs-left" :style="{ 'background': props.item.online ? '#2d922db8' : '#ff5252' , color: 'white'}">{{ props.item.lab }}</td>
                <td class="text-xs-left" :style="{ 'background': props.item.online ? '#2d922db8' : '#ff5252' , color: 'white'}">{{ props.item.online ? "Connected" : "Disconnected" }}</td>
                <td class="text-xs-left" :style="{ 'background': props.item.online ? '#2d922db8' : '#ff5252' , color: 'white'}">
                    <v-icon style="padding: 5px;" class="cursor" medium color="white" title="Delete" @click="DeleteSystem(props.item)" >delete</v-icon>
                </td>
            </template>
        </v-data-table>
    </v-flex>
</template>
<script>
export default {
    data(){
        return {
            total_systems : [],
            headers    : [
                { text: 'S.No', value: false },
                { text: 'System No', value: 'system_no' },
                { text: 'Lab No', value: 'lab' },
                { text: 'Status', value: 'status' },
                { text: 'Details', value: false }
            ],
        }
    },
    computed:{
        Systems(){
            return this.total_systems.map(x => { return { ...x , online : this.$store.getters.GetOnline.findIndex(y => parseInt(y.system_no) == x.system_no) >= 0 ? true : false }})
        },
        GetOnline(){
            return this.$store.getters.GetOnline
        }
    },
    watch:{
        GetOnline(){
            this.FindSystems()
        }
    },
    methods:{
        DeleteSystem(data){
             
            this.$eventHub.$emit("LOADING",'false');
            this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/delete-systems`,data)
                .then(response => {

                    if(response && response['data'] && response['data']['response']){
                        
                        let index = this.total_systems.findIndex(x => x.system_no == response['data']['response']['system_no'] && x.lab == response['data']['response']['lab'] )
                        index > -1 ? this.total_systems.splice(index,1) : {}
                        this.$eventHub.$emit("LOADING",'false');

                    }else{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Failed To Delete System","Color":"red","Close": false });
                    }
                })
                .catch(error => {

                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Deleting Systems","Color":"red","Close": false });
                })

        },
        FindSystems(){

             this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/systems`,{})
                .then(response => {

                    if(response && response['data'] && response['data']['response']){
                        
                        this.total_systems = response['data']['response']
                        this.$Socket.GetOnline();
                        this.$eventHub.$emit("LOADING",'false');

                    }else{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Failed To Fetch Systems","Color":"red","Close": false });
                    }
                })
                .catch(error => {

                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Fetching Systems","Color":"red","Close": false });
                })
        }
    },
    mounted(){

        this.FindSystems()
    }
}
</script>
<style>
.cursor{
    cursor: pointer;
}
</style>